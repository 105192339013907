import React, { Component } from 'react'
import { Button, Header, Icon, Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { WAYLAY_DOMAIN } from '../../lib/env'
import ConfirmationModal from '../confirmationModal'

class Detail extends Component {
  render () {
    const { tenant, handleEnable, handleDisable, handleDelete } = this.props
    if (!tenant) return null

    const statusButton = getStatusButton(tenant.status, handleEnable, handleDisable)

    return (
      <div>
        {renderHeader(tenant, handleDelete)}
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{tenant.name}</Table.HeaderCell>
              <Table.HeaderCell>{tenant.domain}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Description</Table.Cell>
              <Table.Cell>{tenant.description}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Custom Data</Table.Cell>
              <Table.Cell>{tenant.custom_data}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Public key</Table.Cell>
              <Table.Cell>
                <code><pre>{tenant.public_key}</pre></code>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Status</Table.Cell>
              <Table.Cell>{statusButton}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>2FA</Table.Cell>
              <Table.Cell>{tenant.twofa ? '🔒 Enabled' : '🔓 Disabled'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{new Date(tenant.created_at).toString()}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{new Date(tenant.updated_at).toString()}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    )
  }
}

function renderHeader (tenant, onDelete) {
  const { domain } = tenant
  const isAdmin = domain === WAYLAY_DOMAIN

  return (
    <div id='top-bar'>
      <Header>Details</Header>
      {!isAdmin && renderEditLink(tenant)}
      {!isAdmin && renderDeleteLink(tenant, onDelete)}
    </div>
  )
}

function renderEditLink ({ id }) {
  return (
    <Link to={`/tenants/${id}/edit`} className='ui green button'>
      <Icon name='pencil' />Edit
    </Link>
  )
}

function renderDeleteLink ({ id, name }, onDelete) {
  return (
    <ConfirmationModal
      header='Delete tenant'
      subject={name}
      onConfirm={() => onDelete(id)}
    />
  )
}

function getStatusButton (status, onEnable, onDisable) {
  if (status === 'enabled') {
    return (
      <Button basic icon color='green' onClick={() => onDisable()}>
        <Icon color='green' name='check circle' />
        Enabled
      </Button>
    )
  } else {
    return (
      <Button basic icon color='red' onClick={() => onEnable()}>
        <Icon color='red' name='minus circle' />
        Disabled
      </Button>
    )
  }
}

export default Detail
