import React from 'react'
import { Table } from '@waylay/react-components'
import { Button, Popup, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ConfirmationModal from '../confirmationModal'
import TableHeaderCellBold from '../../common/tableHeaderCellBold'
import groups from '../../actions/groups'
import moment from 'moment'

export default ({ groups, onListUpdated }) => (
  <Table>
    <Table.Header>
      <Table.Row>
        <TableHeaderCellBold>ID</TableHeaderCellBold>
        <TableHeaderCellBold>Tenant</TableHeaderCellBold>
        <TableHeaderCellBold>Name</TableHeaderCellBold>
        <TableHeaderCellBold>Description</TableHeaderCellBold>
        <TableHeaderCellBold>Updated At</TableHeaderCellBold>
        <TableHeaderCellBold>Created At</TableHeaderCellBold>
        <TableHeaderCellBold>Actions</TableHeaderCellBold>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {groups.map(group => (
        <Table.Row key={group.id}>
          <Table.Cell>
            <Link to={`/groups/${group.id}`}>
              {group.id}
            </Link>
          </Table.Cell>
          <Table.Cell>
            {group.tenant_id}
          </Table.Cell>
          <Table.Cell>
            {group.name}
          </Table.Cell>
          <Table.Cell>
            {group.description}
          </Table.Cell>
          <Table.Cell>
            {timestamp(group.updated_at)}
          </Table.Cell>
          <Table.Cell>
            {timestamp(group.created_at)}
          </Table.Cell>
          <Table.Cell>
            <Button.Group basic size='tiny'>
              <Link className='ui icon button' to={`/groups/${group.id}/edit`}>
                <Icon name='pencil' /> Edit
              </Link>
              <ConfirmationModal
                header={`Remove "${group.name}"`}
                subject={group.name}
                onConfirm={(event) => onDeleteConfirmation(group.id, onListUpdated)}
              />
            </Button.Group>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

const timestamp = (datetime) => {
  return datetime
    ? (
      <Popup
        basic inverted position='top center'
        trigger={
          <span>
            {moment(datetime).fromNow()}
          </span>
      }
        content={
        moment(datetime).format('LLL')
      }
      />
      )
    : null
}

function onDeleteConfirmation (id, onListUpdated) {
  groups.removeGroup(id)
    .then(() => onListUpdated())
}
