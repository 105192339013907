import React, { Component } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Menu, Header } from 'semantic-ui-react'
import { get, reject } from 'lodash'

import DetailView from '../../components/tenants/detail'
import SettingsView from '../../components/tenants/settings'
import UsersView from '../../components/tenants/users'
import Breadcrumb from '../../components/breadcrumb'
import Roles from '../../components/roles/table'
import Applications from '../../components/applications/table'
import { withRouter } from '../../common/withRouter'
import { errToast } from '../../lib/toast'
import { auth, roles, tenants, users, applications } from '../../actions'

const { forgotPassword } = auth
const { deleteRole } = roles
const {
  fetchTenant,
  updateTenant,
  addUserToTenant,
  removeUserFromTenant,
  deleteTenantSetting,
  removeTenant
} = tenants
const { fetchUsers, fetchServiceUsers } = users
const { removeTenantFromApplication } = applications

class Detail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      tenant: undefined,
      users: undefined,
      serviceUsers: undefined
    }
  }

  componentDidMount () {
    const tenantId = get(this.props, ['match', 'params', 'id'])

    fetchTenant(tenantId)
      .then(({ tenant }) => this.setState({ tenant }))
      .catch(errToast)

    fetchUsers()
      .then(({ users }) => this.setState({ users }))
      .catch(errToast)

    fetchServiceUsers()
      .then(({ users }) => this.setState({ serviceUsers: users }))
      .catch(errToast)
  }

  enableTenant = () => {
    this.handleUpdate({ status: 'enabled' })
  }

  disableTenant = () => {
    this.handleUpdate({ status: 'disabled' })
  }

  handleUpdate = (properties) => {
    const tenantId = get(this.state, ['tenant', 'id'])
    updateTenant(tenantId, properties)
      .then(() => {
        const { tenant } = this.state
        const newTenant = { ...tenant, ...properties }
        this.setState({ tenant: newTenant })
      })
      .catch(errToast)
  }

  handleDeleteSetting = (key) => {
    const tenantId = get(this.state, ['tenant', 'id'])
    deleteTenantSetting(tenantId, key)
      .then(() => {
        const { tenant } = this.state

        const newTenantSettings = reject(tenant.settings, { key })
        const newTenant = { ...tenant, settings: newTenantSettings }

        this.setState({ tenant: newTenant })
      })
      .catch(errToast)
  }

  addUser = (userId) => {
    const tenantId = get(this.state, ['tenant', 'id'])
    addUserToTenant(userId, tenantId)
      .then(() => {
        const { users, tenant, serviceUsers } = this.state

        let newUser = {}
        if (this.props.location.pathname.includes('serviceUsers')) {
          newUser = serviceUsers.find((u) => u.id === userId)
        } else {
          newUser = users.find((u) => u.id === userId)
        }
        const newTenantUsers = [...tenant.users, newUser]
        const newTenant = { ...tenant, users: newTenantUsers }
        this.setState({ tenant: newTenant })
      })
      .catch(errToast)
  }

  deleteUser = (userId) => {
    const tenantId = get(this.state, ['tenant', 'id'])
    removeUserFromTenant(userId, tenantId)
      .then(() => {
        const { tenant } = this.state

        const newTenantUsers = reject(tenant.users, { id: userId })
        const newTenant = { ...tenant, users: newTenantUsers }

        this.setState({ tenant: newTenant })
      })
      .catch(errToast)
  }

  removeRole = (roleId) => {
    deleteRole(roleId)
      .then((role) => {
        const { tenant } = this.state

        const newTenantRoles = reject(tenant.roles, { id: roleId })
        const newTenant = { ...tenant, roles: newTenantRoles }

        this.setState({ tenant: newTenant })
      })
      .catch(errToast)
  }

  forgotPassword = (email) => {
    forgotPassword(email)
      .then(() => toast.success(`Email sent to ${email}`))
      .catch(errToast)
  }

  deleteApplication = (applicationId) => {
    const tenantId = get(this.props, ['match', 'params', 'id'])

    removeTenantFromApplication(applicationId, tenantId)
      .then(() => {
        const { tenant } = this.state
        const newTenantApplications = reject(tenant.applications, {
          id: applicationId
        })
        const newTenant = { ...tenant, applications: newTenantApplications }

        this.setState({ tenant: newTenant })
      })
      .then(() => toast.warn('Application successfully deleted'))
      .catch(errToast)
  }

  deleteTenant = (id) => {
    removeTenant(id)
      .then(() => {
        toast.warn('Tenant successfully deleted')
        this.props.history('/tenants', { replace: false })
      })
      .catch(errToast)
  }

  getModals () {
    const modal = {
      icon: 'unlinkify',
      action: 'Unlink'
    }

    return {
      userModal: {
        ...modal,
        header: 'Unlink user',
        onConfirm: (id) => () => this.deleteUser(id)
      },
      roleModal: {
        ...modal,
        header: 'Unlink role',
        onConfirm: (id) => () => this.removeRole(id)
      },
      appModal: {
        ...modal,
        header: 'Unlink application',
        onConfirm: (id) => () => this.deleteApplication(id)
      }
    }
  }

  render () {
    const { tenant, users, serviceUsers } = this.state

    if (!tenant) return null

    const { userModal, roleModal, appModal } = this.getModals()

    return (
      <div>
        <Menu secondary>
          <Breadcrumb titles={[undefined, tenant.name]} />
        </Menu>
        <div style={{ display: 'flex' }}>
          <div>
            <Menu pointing secondary vertical fluid>
              <NavLink className='item' to={`/tenants/${tenant.id}/details`}>
                Details
              </NavLink>
              <NavLink className='item' to={`/tenants/${tenant.id}/users`}>
                Users
              </NavLink>
              <NavLink
                className='item'
                to={`/tenants/${tenant.id}/serviceUsers`}
              >
                Service Users
              </NavLink>
              <NavLink className='item' to={`/tenants/${tenant.id}/settings`}>
                Settings
              </NavLink>
              <NavLink className='item' to={`/tenants/${tenant.id}/roles`}>
                Roles
              </NavLink>
              <NavLink
                className='item'
                to={`/tenants/${tenant.id}/applications`}
              >
                Applications
              </NavLink>
            </Menu>
          </div>
          <div style={{ flex: 1, margin: '0 15px' }}>
            <div>
              <Routes>
                <Route
                  path='/details'
                  element={
                    <DetailView
                      tenant={tenant}
                      handleEnable={this.enableTenant}
                      handleDisable={this.disableTenant}
                      handleDelete={this.deleteTenant}
                    />
                  }
                />
                <Route
                  path='/users'
                  element={
                    <UsersView
                      tenant={tenant}
                      users={users}
                      addUser={this.addUser}
                      forgotPassword={this.forgotPassword}
                      modal={userModal}
                    />
                  }
                />
                <Route
                  path='/serviceUsers'
                  element={
                    <UsersView
                      tenant={tenant}
                      users={serviceUsers}
                      addUser={this.addUser}
                      forgotPassword={this.forgotPassword}
                      modal={userModal}
                    />
                  }
                />
                <Route
                  path='/roles'
                  element={
                    <span>
                      <div id='top-bar'>
                        <Header>Roles</Header>
                      </div>
                      <Roles roles={tenant.roles} modal={roleModal} />
                    </span>
                  }
                />
                <Route
                  path='/settings'
                  element={
                    <SettingsView
                      settings={tenant.settings}
                      tenantId={tenant.id}
                      onDeleteSetting={this.handleDeleteSetting}
                    />
                  }
                />
                <Route
                  path='/applications'
                  element={
                    <span>
                      <div id='top-bar'>
                        <Header>Applications</Header>
                      </div>
                      <Applications
                        applications={tenant.applications}
                        modal={appModal}
                      />
                    </span>
                  }
                />
                <Route
                  path='/'
                  element={<Navigate to={`/tenants/${tenant.id}/details`} />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Detail)
