import React, { Component } from 'react'
import { get } from 'lodash'

import EditView from '../../components/tenants/edit'
import { withRouter } from '../../common/withRouter'
import { errToast } from '../../lib/toast'
import { tenants } from '../../actions'

const { fetchTenant, updateTenant } = tenants

class Detail extends Component {
  constructor (props) {
    super(props)
    this.state = { tenant: undefined }
  }

  componentDidMount () {
    const tenantId = get(this.props, ['match', 'params', 'id'])
    fetchTenant(tenantId)
      .then(({ tenant }) => this.setState({ tenant }))
      .catch(errToast)
  }

  onSubmit = (id, state) => {
    updateTenant(id, state)
      .then(() => this.props.history(-1))
      .catch(errToast)
  }

  render () {
    const { tenant } = this.state

    if (!tenant) return null
    return <EditView tenant={tenant} handleSubmit={this.onSubmit} />
  }
}

export default withRouter(Detail)
