import React, { Component } from 'react'
import { concrete, Icon, carbon } from '@waylay/react-components'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { get } from 'lodash'

import { WAYLAY_DOMAIN } from '../lib/env'
import authClient from '../lib/client'

import Logout from '../containers/logout'

const Nav = styled('nav')`
  height: 50px;
  background: ${carbon};
  border-bottom: solid 1px ${concrete};

  padding: 10px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: white;
    margin: 0 10px;
  }
`
const Logo = styled(Link)`
  height: 25px;
  max-height: 25px;

  > img {
    height: 25px;
    max-height: 25px;
  }
`

class TopNav extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      logo: 'https://static.waylay.io/logos/waylay/waylay-vector-logo-horizontal-white-no-caption.svg'
    }
  }

  componentDidMount () {
    this.setState({ loading: true })
    authClient.tenantBranding(WAYLAY_DOMAIN)
      .then(res => {
        const logo = get(res, 'branding.logo')
        this.setState({ logo })
      })
      .catch(() => { /* We'll just ignore this and show the default icon */ })
      .finally(() => this.setState({ loading: false }))
  }

  render () {
    const { loading, logo } = this.state

    return (
      <Nav>
        <div>
          {!loading && (
            <Logo to='/' style={{ margin: 0 }}>
              <img src={logo} />
            </Logo>
          )}
        </div>
        <div>
          <Link to='/me'>
            <Icon name='account_circle' /> Me
          </Link>
          <Logout>
            <Icon name='exit_to_app' /> Logout
          </Logout>
        </div>
      </Nav>
    )
  }
}

export default TopNav
