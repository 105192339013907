import React, { Component } from 'react'
import { Button, Form, Message, Segment } from 'semantic-ui-react'
import { get } from 'lodash'

import { WAYLAY_DOMAIN } from '../../lib/env'
import authClient from '../../lib/client'

import '../login/login.css'

class PasswordForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      name: 'Waylay',
      logo: 'https://static.waylay.io/logos/waylay/waylay-vector-logo.svg'
    }
  }

  componentDidMount () {
    this.setState({ loading: true })
    authClient.tenantBranding(WAYLAY_DOMAIN)
      .then(res => {
        const logo = get(res, 'branding.logo')
        const name = get(res, 'branding.name')
        this.setState({ logo, name })
      })
      .catch(() => { /* We'll just ignore this and show the default icon */ })
      .finally(() => this.setState({ loading: false }))
  }

  updateForm (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  submitForm (event) {
    event.preventDefault()
    const { email } = this.state
    this.props.handleSubmit(email)
  }

  render () {
    const { loading: logoLoading, logo, name } = this.state
    const { success, loading, message, error } = this.props

    return (
      <div>
        <Form onSubmit={e => this.submitForm(e)} className='large'>
          {!success && (
            <Segment>
              {!logoLoading && <CenteredLogo src={logo} alt={name} />}
              <Form.Field>
                <Form.Input
                  label='Email'
                  name='email'
                  placeholder='Email'
                  type='email'
                  onChange={e => this.updateForm(e)}
                />
              </Form.Field>
              <Button
                primary fluid size='large' type='submit'
                loading={loading}
                disabled={loading}
                content='Forgot Password'
              />
            </Segment>
          )}
          <Message
            positive={Boolean(success)}
            negative={Boolean(error)}
            hidden={!message && !error}
            content={message || error}
          />
        </Form>
      </div>
    )
  }
}

const CenteredLogo = props => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <img {...props} style={{ maxWidth: '100%', margin: '10px 0' }} />
  </div>
)

export default PasswordForm
