import React, { Component } from 'react'
import { Dropdown, Header } from 'semantic-ui-react'
import Gravatar from 'react-gravatar'
import _ from 'lodash'

import Table from '../../components/users/table'

class User extends Component {
  parseUsersForDropdown (users, options) {
    return users.map((user) => {
      const text = user.fullname || user.email
      return {
        key: user.id,
        text,
        value: user.id,
        content: (
          <div>
            <Gravatar
              email={user.email}
              className='ui mini inline image rounded'
            />{' '}
            {text}
          </div>
        ),
        ...options
      }
    })
  }

  deleteUser (id) {
    return id && this.props.deleteUser(id)
  }

  addUser (id) {
    return id && this.props.addUser(id)
  }

  render () {
    const { tenant, forgotPassword, modal } = this.props
    const { users } = this.props
    if (!tenant || !users) return null

    const usersInTenant = _.filter(users, (user) => {
      return _.find(tenant.users, { id: user.id })
    })

    const usersNotInTenant = _.filter(users, (user) => {
      return !_.find(tenant.users, { id: user.id })
    })

    const userDropdown = this.parseUsersForDropdown(usersNotInTenant, {
      onClick: (event, data) => this.addUser(data.value)
    })

    // add clear selection item
    userDropdown.unshift({ key: null, text: 'No user', value: '' })

    return (
      <div>
        <div id='top-bar'>
          <Header>Users</Header>
          <Dropdown
            selection
            search
            options={userDropdown}
            placeholder='Add a user'
            style={{ marginLeft: 20 }}
          />
        </div>
        <Table
          users={usersInTenant}
          delete={(id) => this.deleteUser(id)}
          resetPassword={forgotPassword}
          modal={modal}
        />
      </div>
    )
  }
}

export default User
