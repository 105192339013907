import AuthClient from '@waylay/auth-client'
import { WAYLAY_DOMAIN, WAYLAY_ACCOUNTS_PUBLIC_URL } from './env'

const { localStorage } = window

const baseURL = WAYLAY_ACCOUNTS_PUBLIC_URL
const domain = WAYLAY_DOMAIN
const token = localStorage.getItem('token')
const timeout = 5000
const withCredentials = process.env.CORS_WITH_CREDENTIALS ?? false

function createAuthClient (customToken) {
  return new AuthClient({ baseURL, domain, token: customToken ?? token, timeout, withCredentials })
}

export default createAuthClient()

export {
  createAuthClient
}
