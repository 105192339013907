import { sortBy } from 'lodash'

export default function sort (collection, sortParam, ascending) {
  collection = sortBy(collection, col => {
    if (typeof col[sortParam] === 'string') return col[sortParam].toLowerCase()
    return col[sortParam]
  })

  if (ascending) return collection
  return collection.reverse()
}
