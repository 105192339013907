import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from '../common/withRouter'

import { auth } from '../actions'

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(auth.logout())
})

const Logout = ({ children, history, logout }) => {
  const onLogout = (event) => {
    event.preventDefault()
    logout()
    history('/login', { replace: true })
  }

  return (
    <a href='' onClick={onLogout}>
      {children}
    </a>
  )
}

export default withRouter(connect(null, mapDispatchToProps)(Logout))
