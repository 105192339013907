import React, { Component } from 'react'
import { Routes, Route } from 'react-router-dom'
import { get } from 'lodash'

import { WAYLAY_DOMAIN } from './lib/env'
import authClient from './lib/client'
import './App.css'

import Login from './containers/login'
import Dashboard from './containers/dashboard'
import ForgotPassword from './containers/password/forgotPassword'
import ResetPassword from './containers/password/resetPassword'

import DEFAULT_FAVICON from './assets/favicon.png'

const DEFAULT_TITLE_NAME = 'Waylay'

class App extends Component {
  componentDidMount () {
    authClient.tenantBranding(WAYLAY_DOMAIN)
      .then(res => {
        const name = get(res, 'branding.name', DEFAULT_TITLE_NAME)
        this.updateSiteTitle(name)
        const favicon = get(res, 'branding.favicon', DEFAULT_FAVICON)
        this.updateSiteFavicon(favicon)
      })
      .catch(() => {
        this.updateSiteTitle(DEFAULT_TITLE_NAME)
        this.updateSiteFavicon(DEFAULT_FAVICON)
      })
  }

  updateSiteTitle (name) {
    document.title = `${name} - Keymaster`
  }

  updateSiteFavicon (favicon) {
    document.getElementById('favicon').href = favicon
  }

  render () {
    return (
      <Routes>
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/forgot' element={<ForgotPassword />} />
        <Route path='/reset/:token' element={<ResetPassword />} />
        <Route path='*' element={<Dashboard />} />
      </Routes>
    )
  }
}

export default App
