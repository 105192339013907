import { reject } from 'lodash'
import { applications as actionTypes } from '../actions/types'

export default (applications = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_APPLICATIONS:
      return [].concat(action.applications)
    case actionTypes.DELETED_APPLICATION:
      return reject(applications, { id: action.id })
    default:
      return applications
  }
}
