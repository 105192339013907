export default {
  FETCH_USER: 'FETCH_USER',
  FETCH_USERS: 'FETCH_USERS',
  CREATED_USER: 'CREATED_USER',
  UPDATED_USER: 'UPDATED_USER',
  UNLOCK_USER: 'UNLOCK_USER',
  DELETED_USER: 'DELETED_USER',
  CLEAR_USER: 'CLEAR_USER',
  FETCH_USER_KEYS_TENANTS: 'FETCH_USER_KEYS_TENANTS',
  ROLE_USER_ADDED: 'ROLE_USER_ADDED',
  ROLE_USER_REMOVED: 'ROLE_USER_REMOVED'
}
