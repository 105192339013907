import React, { Component } from 'react'
import { Header, Icon } from 'semantic-ui-react'
import { Button } from '@waylay/react-components'
import { NavLink } from 'react-router-dom'

import CompaniesTable from '../../components/companies/list'
import authClient from '../../lib/client'

class CompaniesList extends Component {
  constructor (props) {
    super(props)
    this.state = { companies: [] }
  }

  async componentDidMount () {
    await this.fetchCompanies()
  }

  async fetchCompanies () {
    const companies = await authClient.companies()
    this.setState({ companies })
  }

  render () {
    const { companies } = this.state
    return (
      <>
        <div id='top-bar'>
          <Header>
            <Icon name='building' />{companies.length} Companies
          </Header>
          <NavLink to='/companies/new' style={{ marginLeft: 20 }}>
            <Button>
              <Icon name='add' />Add company
            </Button>
          </NavLink>
        </div>
        <CompaniesTable companies={companies} onListUpdated={() => this.fetchCompanies()} />
      </>
    )
  }
}

export default CompaniesList
