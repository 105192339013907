import React, { Component } from 'react'
import { toast } from 'react-toastify'

import UserRoles from '../../components/users/roles'
import { errToast } from '../../lib/toast'
import client from '../../lib/client'

class Roles extends Component {
  componentDidMount () {
    this.fetchUser()
    this.fetchCustomRoles()
    this.fetchPredefinedRoles()
  }

  fetchUser () {
    const { user } = this.props
    return client.user(user)
      .then(user => this.setState({ user }))
      .catch(() => errToast({ message: 'Something went wrong while fetching the user' }))
  }

  fetchCustomRoles () {
    return client.roles()
      .then(customRoles => this.setState({ customRoles }))
      .catch(() => errToast({ message: 'Something went wrong while fetching the custom roles' }))
  }

  fetchPredefinedRoles () {
    return client.predefinedRoles()
      .then((predefinedRoles = []) => {
        const pRolesWithPredefinedProp = predefinedRoles.map(role => ({ ...role, predefined: true }))
        this.setState({ predefinedRoles: pRolesWithPredefinedProp })
      })
      .catch(() => errToast({ message: 'Something went wrong while fetching the predefined roles' }))
  }

  handleAddRole = ({ role, tenant }) => {
    const { user, onRoleChange } = this.props

    client.addRoleToUser(user, role, tenant)
      .then(() => this.fetchUser())
      .then(() => {
        toast.success('Role assigned')
        onRoleChange(this.state.user.permissions)
      })
      .catch(errToast)
  }

  handleRemoveRole = (role, tenant) => {
    const { user, onRoleChange } = this.props

    client.removeRoleFromUser(user, role, tenant)
      .then(() => this.fetchUser())
      .then(() => {
        toast.success('Role removed')
        onRoleChange(this.state.user.permissions)
      })
      .catch(errToast)
  }

  getModal () {
    return {
      icon: 'unlinkify',
      action: 'Unlink',
      header: 'Unlink role',
      onConfirm: (id, tenant) => () => this.handleRemoveRole(id, tenant)
    }
  }

  render () {
    const modal = this.getModal()

    const { user, customRoles, predefinedRoles } = this.state || {}

    if (!user) return null
    return (
      <UserRoles
        user={user}
        customRoles={customRoles}
        predefinedRoles={predefinedRoles}
        onAddRole={this.handleAddRole}
        modal={modal}
      />
    )
  }
}

export default Roles
