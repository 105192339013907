import React, { useEffect, useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { withRouter } from '../common/withRouter'

const TenantsDropdown = ({ tenants, multiple, selectedTenant, onTenantSelected, location }) => {
  const options = tenantsToOptions(tenants)
  const [selectedValue, setSelectedValue] = useState(multiple ? [] : '')

  useEffect(() => {
    setSelectedValue(multiple ? [] : '')
  }, [location.pathname, multiple])

  useEffect(() => {
    if (multiple) {
      const selectedTenantKeys = tenants.filter(tenant => tenant.id === selectedTenant).map(tenant => tenant.id)
      setSelectedValue(selectedTenantKeys)
    } else {
      const selectedTenantKey = tenants.find(tenant => tenant.id === selectedTenant)?.id || ''
      setSelectedValue(selectedTenantKey)
    }
  }, [selectedTenant, multiple, tenants])

  const handleChange = (e, data) => {
    onTenantSelected(data.value)
    setSelectedValue(data.value)
  }

  return (
    <Dropdown
      search
      selection
      placeholder='Tenant'
      value={selectedValue}
      options={options}
      multiple={multiple}
      onChange={handleChange}
    />
  )
}

export default withRouter(TenantsDropdown)

function tenantsToOptions (tenants) {
  const ddTenants = tenants.map(({ id, name }) => ({
    key: id,
    text: name,
    value: id
  }))

  // add clear selection item
  ddTenants.unshift({ key: null, text: 'No selection', value: '' })
  return ddTenants
}
