import React from 'react'
import AddTenant from './addTenant'
import TenantsList from '../tenants/list'

export default ({ tenants = [], userTenants = [], handleAddUserToTenant, handleRemoveUserFromTenant, modal }) => (
  <div>
    <AddTenant allTenants={tenants} tenants={userTenants} addTenant={handleAddUserToTenant} />
    <TenantsList collapsing tenants={userTenants} remove={handleRemoveUserFromTenant} modal={modal} />
  </div>
)
