import React, { Component } from 'react'
import { connect } from 'react-redux'

import PermissionsDropdown from '../components/permissionsDropdown'
import { handleErrorWithToast } from '../lib/toast'
import { permissions } from '../actions'

const mapStateToProps = (state) => ({
  permissions: state.permissions
})

const mapDispatchToProps = (dispatch) => ({
  fetchPermissions: () => dispatch(permissions.fetchPermissions())
})

class PermissionsDropdownContainer extends Component {
  componentDidMount () {
    const { fetchPermissions } = this.props
    handleErrorWithToast(fetchPermissions)()
  }

  render () {
    return <PermissionsDropdown {...this.props} />
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionsDropdownContainer)
