import React, { Component } from 'react'
import { withRouter } from '../../common/withRouter'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import UserForm from '../../components/users/new'
import { errToast } from '../../lib/toast'
import { users } from '../../actions'

const mapDispatchToProps = (dispatch) => ({
  createUser: (user) => dispatch(users.createUser(user))
})

class UserFormContainer extends Component {
  onSubmit = (formState) => {
    const { createUser, history, location } = this.props

    createUser(formState)
      .then(({ user }) => {
        const { id, firstname, lastname } = user

        toast.success(`User created: ${firstname} ${lastname ?? ''}`)
        history(
          location.pathname.includes('serviceUsers')
            ? `/serviceUsers/${id}`
            : `/users/${id}`,
          { replace: false }
        )
      })
      .catch(errToast)
  }

  render () {
    return <UserForm handleSubmit={this.onSubmit} />
  }
}

export default withRouter(connect(null, mapDispatchToProps)(UserFormContainer))
