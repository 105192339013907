import React from 'react'
import { Container, Grid, Header, Segment } from 'semantic-ui-react'
import { Button } from '@waylay/react-components'

import { jwtDecode } from 'jwt-decode'
import CopyToClipboard from './copyToClipboard'

const headerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 15
}

export default (props) => {
  const { token } = props

  const [header, body, signature] = token.split('.')
  const decodedHeader = jwtDecode(token, { header: true })
  const decodedBody = jwtDecode(token)
  const COLORS = {
    header: '#fb015b',
    body: '#d63aff',
    signature: '#00b9f1'
  }

  return (
    <Container>
      <div style={headerStyles}>
        <Header style={{ margin: 0 }}>JWT token</Header>
        <CopyToClipboard
          target={<Button>Copy token</Button>}
          value={token}
        />
      </div>
      <Grid divided='vertically'>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Segment attached>
              <code style={{ wordWrap: 'break-word', fontSize: '1.2em' }}>
                <span style={{ color: COLORS.header }}>{header}</span>.
                <span style={{ color: COLORS.body }}>{body}</span>.
                <span style={{ color: COLORS.signature }}>{signature}</span>
              </code>
            </Segment>
          </Grid.Column>

          <Grid.Column>
            <Segment.Group>
              {/* header */}
              <Header as='h5' attached='top'>Header</Header>
              <Segment attached>
                <pre>
                  <code style={{ color: COLORS.header }}>
                    {JSON.stringify(decodedHeader, null, 2)}
                  </code>
                </pre>
              </Segment>
              {/* body */}
              <Header as='h5' attached>Body</Header>
              <Segment attached>
                <pre>
                  <code style={{ color: COLORS.body }}>
                    {JSON.stringify(decodedBody, null, 2)}
                  </code>
                </pre>
              </Segment>
            </Segment.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}
