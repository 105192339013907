import React, { Component } from 'react'
import { Button } from 'semantic-ui-react'
import { Table } from '@waylay/react-components'
import { sortBy } from 'lodash'

import ConfirmationModal from '../confirmationModal'
import TableHeaderCellBold from '../../common/tableHeaderCellBold'

class CustomTable extends Component {
  constructor (props) {
    super(props)
    this.state = { concepts: [] }
  }

  componentDidMount () {
    this.setState({
      concepts: sortBy(this.props.concepts, 'name')
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({
      concepts: sortBy(nextProps.concepts, 'name')
    })
  }

  render () {
    const { concepts } = this.state
    const { delete: onDelete } = this.props

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <TableHeaderCellBold>
              Name
            </TableHeaderCellBold>
            <TableHeaderCellBold style={{ width: 120 }}>Actions</TableHeaderCellBold>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {concepts.map(concept => renderConcept(concept, onDelete))}
        </Table.Body>
      </Table>
    )
  }
}

function renderConcept (concept, onDelete) {
  return (
    <Table.Row key={concept}>
      <Table.Cell>{concept}</Table.Cell>
      <Table.Cell>
        <Button.Group basic size='tiny'>
          {renderModal(concept, onDelete)}
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  )
}

function renderModal (concept, onDelete) {
  return (
    <ConfirmationModal
      header='Delete Concept'
      subject={concept}
      onConfirm={() => onDelete(concept)}
    />
  )
}

export default CustomTable
