import React, { Component } from 'react'
import { Form, Radio } from 'semantic-ui-react'

export default class RadioSubject extends Component {
  state = { value: this.props.subject }

  handleChange = (e, { value }) => {
    this.setState({ value })
    this.props.onChange(value)
  }

  render () {
    return (
      <Form style={{ marginBottom: '12px' }}>
        <Form.Field>
          Choose subject:
        </Form.Field>
        <Form.Field>
          <Radio
            label='User Token'
            name='radioGroup'
            value='user'
            checked={this.state.value === 'user'}
            onChange={this.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label='Application Token'
            name='radioGroup'
            value='application'
            checked={this.state.value === 'application'}
            onChange={this.handleChange}
          />
        </Form.Field>
      </Form>
    )
  }
}
