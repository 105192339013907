import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Header, Icon } from 'semantic-ui-react'
import { Button } from '@waylay/react-components'

import { WAYLAY_DOMAIN } from '../../lib/env'
import { handleErrorWithToast, errToast } from '../../lib/toast'

import TenantList from '../../components/tenants/list'

import { tenants } from '../../actions'

const mapStateToProps = (state) => ({
  tenants: state.tenants
})

const mapDispatchToProps = (dispatch) => ({
  fetchTenants: () => dispatch(tenants.fetchTenants()),
  removeTenant: id => dispatch(tenants.removeTenant(id))
})

class Tenants extends Component {
  componentDidMount () {
    const { fetchTenants } = this.props
    handleErrorWithToast(fetchTenants)()
  }

  onDelete = (id) => {
    const { removeTenant } = this.props
    removeTenant(id)
      .then(() => toast.warn('Tenant successfully deleted'))
      .catch(errToast)
  }

  getModal () {
    return {
      header: 'Delete tenant',
      onConfirm: (id) => () => this.onDelete(id)
    }
  }

  render () {
    const { tenants } = this.props
    const modal = this.getModal()

    return (
      <div>
        <div id='top-bar'>
          <Header>
            <Icon name='key' />{tenants.length} Tenants
          </Header>
          <NavLink to='/tenants/new' style={{ marginLeft: 20 }}>
            <Button>
              <Icon name='add' />Add tenant
            </Button>
          </NavLink>
        </div>
        <TenantList tenants={tenants} adminTenant={WAYLAY_DOMAIN} modal={modal} />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tenants)
