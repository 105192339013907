import client from '../lib/client'
import { roles as types } from './types'

function fetchRole (id) {
  return client.role(id)
    .then(role => ({
      type: types.FETCH_ROLE,
      role
    }))
}

function fetchRoles () {
  return client.roles()
    .then(roles => ({
      type: types.FETCH_ROLES,
      roles
    }))
}

function createRole (details) {
  return client.createRole(details)
    .then(role => ({
      type: types.ROLE_CREATED,
      role
    }))
}

function deleteRole (id) {
  return client.deleteRole(id)
    .then(() => ({
      type: types.ROLE_REMOVED,
      id
    }))
}

function updateRole (id, details) {
  return client.updateRole(id, details)
    .then(role => ({
      type: types.ROLE_UPDATED,
      role
    }))
}

function addPermissionToRole (roleId, permissionId) {
  return client.addPermissionsToRole(roleId, permissionId)
    .then(permission => ({
      type: types.PERMISSION_ROLE_ADDED,
      permission: { roleId, permissionId }
    }))
}

function removePermissionFromRole (roleId, permissionId) {
  return client.removePermissionFromRole(roleId, permissionId)
    .then(() => ({
      type: types.PERMISSION_ROLE_REMOVED,
      permission: { roleId, permissionId }
    }))
}

function fetchRoleUsers (roleId, loginEnabled) {
  return client.roleUsers(roleId, { loginEnabled })
    .then(users => ({
      type: types.FETCH_ROLE_USERS,
      users
    }))
}

export default {
  fetchRole,
  fetchRoles,

  createRole,
  deleteRole,
  updateRole,

  addPermissionToRole,
  removePermissionFromRole,

  fetchRoleUsers
}
