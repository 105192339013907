export default {
  LOGIN_USER: 'LOGIN_USER',
  SET_TOKEN: 'SET_TOKEN',
  LOGOUT_USER: 'LOGOUT_USER',

  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  PASSWORD_RESET: 'PASSWORD_RESET',
  TOKEN_VALID: 'TOKEN_VALID',

  FETCH_SSO_PROVIDERS: 'FETCH_SSO_PROVIDERS'
}
