import React, { Component } from 'react'
import { withRouter } from '../../common/withRouter'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import GroupForm from '../../components/groups/form'
import { errToast } from '../../lib/toast'
import { groups } from '../../actions'

const mapDispatchToProps = (dispatch) => ({
  createGroup: (user) => dispatch(groups.createGroup(user))
})

class GroupFormContainer extends Component {
  onSubmit = (formState) => {
    const { createGroup, history } = this.props

    createGroup(formState)
      .then(({ group }) => {
        const { name } = group
        toast.success(`Group created: ${name}`)
        history('/groups', { replace: false })
      })
      .catch(errToast)
  }

  render () {
    return <GroupForm action='New Group' handleSubmit={this.onSubmit} />
  }
}

export default withRouter(connect(
  null,
  mapDispatchToProps
)(GroupFormContainer))
