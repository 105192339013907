import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'

import { withRouter } from '../../common/withRouter'
import NewTenantForm from '../../components/tenants/new'
import { errToast } from '../../lib/toast'
import { tenants } from '../../actions'

const { createTenant } = tenants

class NewTenantContainer extends Component {
  onSubmit = (formState) => {
    if (isEmpty(formState.name?.trim())) {
      return errToast({ message: 'Name is required' })
    }

    createTenant(formState)
      .then(({ tenant }) => {
        const { name } = tenant
        toast.success(`Tenant created: ${name}`)
        return tenant
      })
      .then(tenant => {
        const { id } = tenant
        const { history } = this.props
        history(`/tenants/${id}/details`, { replace: false })
      })
      .catch(errToast)
  }

  render () {
    return <NewTenantForm {...this.props} handleSubmit={this.onSubmit} />
  }
}

export default withRouter(NewTenantContainer)
