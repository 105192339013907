import React, { Component } from 'react'
import { withRouter } from '../../common/withRouter'
import { toast } from 'react-toastify'

import CompanyForm from '../../components/companies/form'
import { errToast } from '../../lib/toast'
import authClient from '../../lib/client'

class CompanyFormContainer extends Component {
  constructor (props) {
    super(props)

    const { name, description } = props
    this.state = { name, description }
  }

  componentDidMount () {
    const { match } = this.props
    const { params } = match

    authClient.company(params.id)
      .then((company) => {
        this.setState({ ...this.state, ...company })
      })
      .catch(errToast)
  }

  onSubmit = (formState) => {
    const { match, history } = this.props
    const { params } = match

    authClient.updateCompany(params.id, formState)
      .then((company) => {
        const { name } = company
        toast.success(`Company updated: ${name}`)
        history('/companies', { replace: false })
      })
      .catch(errToast)
  }

  render () {
    const { name, description } = this.state

    return (
      <CompanyForm
        action='Edit Company'
        name={name}
        description={description}
        handleSubmit={this.onSubmit}
      />
    )
  }
}

export default withRouter(CompanyFormContainer)
