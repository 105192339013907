import React, { Component } from 'react'
import { withRouter } from '../../common/withRouter'
import { toast } from 'react-toastify'

import CompanyForm from '../../components/companies/form'
import { errToast } from '../../lib/toast'
import authClient from '../../lib/client'

class CompanyFormContainer extends Component {
  onSubmit = (formState) => {
    const { history } = this.props

    authClient.createCompany(formState)
      .then((company) => {
        const { name, id } = company
        toast.success(`Company created: ${name}`)
        history(`/companies/${id}`, { replace: false })
      })
      .catch(errToast)
  }

  render () {
    return <CompanyForm action='New Company' handleSubmit={this.onSubmit} />
  }
}

export default withRouter(CompanyFormContainer)
