import React from 'react'
import MeView from '../components/me'

const MeContainer = () => {
  const token = window.localStorage.getItem('token')

  return (
    <MeView token={token} />
  )
}

export default MeContainer
