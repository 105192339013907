import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router'
import { withRouter } from '../common/withRouter'
import querystring from 'querystring'
import { get } from 'lodash'

import { WAYLAY_IDENTITY_PUBLIC_URL } from '../lib/env'
import { isAuthenticated } from '../lib/auth'

import LoginRedirector from '../components/login'
import Login from '../components/login/login'

import { auth } from '../actions'

const { login, credentialsLogin } = auth

const REACT_APP_LOGIN_URL = WAYLAY_IDENTITY_PUBLIC_URL || 'https://login.waylay.io'

const hasIdentityService = Boolean(WAYLAY_IDENTITY_PUBLIC_URL)

const mapDispatchToProps = (dispatch) => ({
  login: (token) => dispatch(login(token)),
  credentialsLogin: (username, password) =>
    dispatch(credentialsLogin(username, password)),
  fetchSSOProviders: () => dispatch(auth.fetchSSOProviders())
})

function getQueryStringObject () {
  const query = get(window, ['location', 'search'], '').replace(/^\?/, '')
  return querystring.parse(query)
}

class LoginContainer extends Component {
  constructor (props) {
    super(props)
    this.state = { loading: false, providers: [] }
  }

  componentDidMount () {
    const { error } = getQueryStringObject()

    this.fetchSSOProviders()

    if (error) return this.handleSSOError(error)

    if (!isAuthenticated()) {
      const token = this.getToken()

      if (token) {
        this.handleToken(token)
        return
      }

      if (hasIdentityService) {
        this.redirectToLoginService()
      }
    }
  }

  getToken () {
    const { token } = getQueryStringObject()
    return token
  }

  redirectToLoginService (error) {
    const baseUrl = get(window, ['location', 'origin'])
    const redirectUrl = encodeURIComponent(`${baseUrl}/login`)
    const location = `${
      hasIdentityService ? REACT_APP_LOGIN_URL : ''
    }/login?redirect_uri=${redirectUrl}`
    window.location = error ? `${location}&error=${error}` : location
  }

  handleToken (token) {
    try {
      this.props.login(token)
      this.props.history('/', { replace: false })
    } catch (error) {
      this.redirectToLoginService(error.message)
    }
  }

  fetchSSOProviders () {
    return this.props
      .fetchSSOProviders()
      .then(({ providers }) => this.setState({ providers }))
      .catch(() => {
        console.log('No SSO config was found')
      })
  }

  handleSSOError (error) {
    const { history } = this.props
    const { pathname } = history.location

    this.setState({ error })
    history(pathname, { replace: true })
  }

  onLogin = (credentials) => {
    if (this.state.loading) return // already submitted request
    this.setState({ loading: true })

    const { username, password } = credentials

    credentialsLogin(username, password)
      .then(({ token }) => {
        if (token) return this.props.history('/', { replace: true })
      })
      .catch((err) => {
        this.setState({ loading: false })

        const { status, code, message } = err
        if (!status) {
          // non-http error
          return this.setState({ error: err })
        }

        if (code === 'ERR_USER_UNVERIFIED') {
          return this.setState({ error: 'Your account is not yet verified' })
        }

        const error =
          err.status === 401
            ? 'Your username or password was incorrect.'
            : message
        this.setState({ error })
      })
  }

  render () {
    const { loading, error, providers } = this.state

    if (isAuthenticated()) {
      return <Navigate to='/' />
    }

    return hasIdentityService
      ? (
        <LoginRedirector />
        )
      : (
        <Login
          loading={loading}
          error={error}
          ssoProviders={providers}
          handleSubmit={this.onLogin}
        />
        )
  }
}

export default withRouter(connect(null, mapDispatchToProps)(LoginContainer))
