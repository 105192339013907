import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { get } from 'lodash'
import { withRouter } from '../../common/withRouter'

import UserInfo from '../../components/users/info'
import { errToast } from '../../lib/toast'
import { auth, users } from '../../actions'

const { forgotPassword } = auth
const { fetchUser, updateUser, unlockUser, deleteUser } = users

class Info extends Component {
  constructor (props) {
    super(props)
    this.state = { user: undefined }
  }

  componentDidMount () {
    const { user } = this.props
    fetchUser(user)
      .then(({ user }) => this.setState({ user }))
      .catch(errToast)
  }

  onEnable = () => {
    this.updateUser({ status: 'enabled' })
  }

  onDisable = () => {
    this.updateUser({ status: 'disabled' })
  }

  onUnlock = () => {
    this.unlockUser()
  }

  onDelete = () => {
    const userId = get(this.state, ['user', 'id'])
    deleteUser(userId)
      .then(() => {
        toast.success('User deleted')
        this.props.history(
          this.props.location.pathname.includes('serviceUsers')
            ? '/serviceUsers'
            : '/users',
          { replace: false }
        )
      })
      .catch(errToast)
  }

  updateUser (properties) {
    const userId = get(this.state, ['user', 'id'])
    updateUser(userId, properties)
      .then(() => {
        const { user } = this.state
        const newUser = { ...user, ...properties }
        this.setState({ user: newUser })
      })
      .catch(errToast)
  }

  unlockUser () {
    const userId = get(this.state, ['user', 'id'])
    unlockUser(userId)
      .then(() => {
        const { user } = this.state
        this.setState({ user: { ...user, is_locked: false } })
      })
      .catch(errToast)
  }

  onPasswordReset = () => {
    const email = get(this.state, ['user', 'email'])
    forgotPassword(email)
      .then(() => toast.success(`Email sent to ${email}`))
      .catch(errToast)
  }

  render () {
    const { user } = this.state

    if (!user) return null
    return (
      <UserInfo
        user={user}
        handleEnable={this.onEnable}
        handleDisable={this.onDisable}
        handleUnlock={this.onUnlock}
        handleDelete={this.onDelete}
        handlePasswordReset={this.onPasswordReset}
      />
    )
  }
}

export default withRouter(Info)
