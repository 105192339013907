import client from '../lib/client'
import { groups as types } from './types'

function fetchGroups () {
  return client.groups()
    .then(groups => ({
      type: types.FETCHED_GROUPS,
      groups
    }))
}

function fetchGroup (id) {
  return client.groups(id)
    .then(group => ({
      type: types.FETCHED_GROUP,
      group
    }))
}

function createGroup (body) {
  return client.createGroup(body)
    .then(group => ({
      type: types.CREATED_GROUP,
      group
    }))
}

function updateGroup (id, body) {
  return client.updateGroup(id, body)
    .then(group => ({
      type: types.UPDATED_GROUP,
      group
    }))
}

function removeGroup (id) {
  return client.removeGroup(id)
    .then(group => ({
      type: types.REMOVED_GROUP,
      group
    }))
}

function assignUserToGroup (userId, groupId) {
  return client.assignUserToGroup(userId, groupId)
    .then(group => ({
      type: types.ASSIGNED_USER_TO_GROUP,
      user: userId,
      group: groupId
    }))
}

function removeUserFromGroup (userId, groupId) {
  return client.removeUserFromGroup(userId, groupId)
    .then(group => ({
      type: types.REMOVED_USER_FROM_GROUP,
      user: userId,
      group: groupId
    }))
}

export default {
  createGroup,
  updateGroup,
  fetchGroups,
  fetchGroup,
  removeGroup,

  assignUserToGroup,
  removeUserFromGroup
}
