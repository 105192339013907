import React from 'react'
import { Table } from '@waylay/react-components'
import TableHeaderCellBold from '../../common/tableHeaderCellBold'

export default ({ permissions }) => (
  <div>
    <Table style={{ width: '100%' }}>
      <Table.Header>
        <Table.Row>
          <TableHeaderCellBold>Name</TableHeaderCellBold>
          <TableHeaderCellBold>Scope</TableHeaderCellBold>
          <TableHeaderCellBold>Operation</TableHeaderCellBold>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {permissions.map(renderPermission)}
      </Table.Body>
    </Table>
  </div>
)

function renderPermission (permission) {
  const { id, name, scope, operation } = permission
  return (
    <Table.Row key={id}>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>{scope}</Table.Cell>
      <Table.Cell>{operation}</Table.Cell>
    </Table.Row>
  )
}
