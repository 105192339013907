/** @jsxImportSource @emotion/react */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { css } from '@emotion/react'
import { withRouter } from '../../common/withRouter'
import {
  Button,
  Icon,
  Input,
  FilterableInput,
  Segment
} from '@waylay/react-components'
import TenantsDropdown from '../../containers/tenantDropdown'

const flexLayout = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const alignLeft = css`
  flex: 1;
  align-self: flex-start;
`

const boost = css`
  width: 300px;
`

const alignRight = css`
  align-self: flex-end;
`

const TopBar = ({
  tenants,
  users,
  onFilter,
  onTenantSelection,
  location,
  filter
}) => {
  const isServiceUsersView = location.pathname.includes('serviceUsers')

  return (
    <div id='top-bar'>
      <div css={flexLayout}>
        <div css={alignLeft}>
          <FilterableInput
            css={alignLeft}
            name='filter'
            initialValue=''
            value=''
            popup={({ resetFilter }) => (
              <Segment.Group>
                <Segment.Footer>
                  <Button
                    onClick={() => {
                      onFilter()
                      resetFilter()
                    }}
                  >
                    <Icon name='clear' /> Clear filter
                  </Button>
                </Segment.Footer>
              </Segment.Group>
            )}
            input={(props) => (
              <Input
                {...props}
                css={boost}
                name='filter'
                placeholder='Filter...'
              />
            )}
            onChange={(input) => onFilter(input)}
          />
        </div>
        <div css={alignRight}>
          <TenantsDropdown onTenantSelected={onTenantSelection} />
          <NavLink
            to={isServiceUsersView ? '/serviceUsers/new' : '/users/new'}
            className='top-bar__action'
          >
            <Button type='primary'>
              <Icon name='person_add' /> Add user
            </Button>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default withRouter(TopBar)
