/* eslint-disable camelcase */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Icon, Popup } from 'semantic-ui-react'
import { Table } from '@waylay/react-components'
import moment from 'moment'

import StatusLabel from '../statusLabel'
import ConfirmationModal from '../confirmationModal'
import TableHeaderCellBold from '../../common/tableHeaderCellBold'

class TenantsList extends Component {
  render () {
    const { tenants, adminTenant, remove, modal } = this.props

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <TableHeaderCellBold>
              ID
            </TableHeaderCellBold>
            <TableHeaderCellBold>
              Name
            </TableHeaderCellBold>
            <TableHeaderCellBold>
              Domain
            </TableHeaderCellBold>
            <TableHeaderCellBold>
              Created
            </TableHeaderCellBold>
            <TableHeaderCellBold>Status</TableHeaderCellBold>
            <TableHeaderCellBold>Actions</TableHeaderCellBold>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tenants.map(t => renderTenant(t, adminTenant, remove, modal))}
        </Table.Body>
      </Table>
    )
  }
}

function renderTenant (tenant, adminDomain, onDelete, modal) {
  const { id, name, domain, status, created_at } = tenant
  const isAdmin = domain === adminDomain

  return (
    <Table.Row key={id}>
      <Table.Cell>
        <Link to={`/tenants/${id}/details`}>{id}</Link>
      </Table.Cell>
      <Table.Cell>
        <Link to={`/tenants/${id}/details`}>{name}</Link>
      </Table.Cell>
      <Table.Cell>
        <Link to={`/tenants/${id}/details`}>{domain}</Link>
      </Table.Cell>
      <Table.Cell>
        <Popup
          basic inverted position='top center'
          trigger={<span>{moment(created_at).fromNow()}</span>}
          content={moment(created_at).format('LLL')}
        />
      </Table.Cell>
      <Table.Cell style={{ width: 130 }}>
        <StatusLabel status={status} />
      </Table.Cell>
      <Table.Cell style={{ width: 180 }}>
        {
          isAdmin
            ? ' ⚠️ Admin domain'
            : renderActions(tenant, modal, onDelete)
        }
      </Table.Cell>
    </Table.Row>
  )
}

function renderActions (tenant, modal, onDelete) {
  const { id, name } = tenant
  return (
    <Button.Group basic size='tiny'>
      <Link className='ui icon button' to={`/tenants/${id}/edit`}>
        <Icon name='pencil' /> Edit
      </Link>
      <ConfirmationModal
        icon={modal.icon}
        action={modal.action}
        header={modal.header}
        subject={name}
        onConfirm={modal.onConfirm(id)}
      />
    </Button.Group>
  )
}

export default TenantsList
