import React, { Component } from 'react'
import { get } from 'lodash'

import sort from '../../lib/sortKeys'
import sortDescription from '../../lib/sort'
import UserApiKeys from '../../components/users/keys'
import { errToast } from '../../lib/toast'
import { keys, tenants, users } from '../../actions'

const { createKey, deleteKey, updateKey } = keys
const { fetchTenants } = tenants
const { fetchUser, fetchUserKeys } = users

class ApiKeys extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedTenant: undefined,
      keys: [],
      column: '',
      ascending: true
    }
  }

  componentDidMount () {
    const { user } = this.props

    fetchUser(user)
      .then(({ user }) => this.setState({ user }))
      .catch(errToast)

    fetchTenants()
      .then(({ tenants }) => this.setState({ tenants }))
      .catch(errToast)

    fetchUserKeys(user)
      .then(({ keys }) => this.setState({ keys }))
      .catch(errToast)
  }

  onTenantSelection = (selectedTenant) => {
    this.setState({ selectedTenant })
  }

  onCreateKey = () => {
    const userId = get(this.state, ['user', 'id'])
    const { selectedTenant } = this.state

    if (selectedTenant) {
      createKey(userId, selectedTenant)
        .then(({ key }) => {
          const { tenants, keys } = this.state
          const tenant = tenants.find(t => t.id === selectedTenant)

          const newKey = { ...key, tenant }
          const newKeys = [...keys, newKey]
          this.setState({ keys: newKeys })
        })
        .catch(errToast)
    }
  }

  onUpdateKey = (keyId, data) => {
    const userId = get(this.state, ['user', 'id'])

    updateKey(userId, keyId, data)
      .then(() => {
        const { keys } = this.state
        const newKeys = keys.map(key => {
          return keyId === key.id
            ? { ...key, ...data }
            : key
        })
        this.setState({ keys: newKeys })
      })
      .catch(errToast)
  }

  onDeleteKey = (keyId) => {
    const userId = get(this.state, ['user', 'id'])

    deleteKey(userId, keyId)
      .then(() => {
        const { keys } = this.state
        const newKeys = keys.filter(k => k.id !== keyId)
        this.setState({ keys: newKeys })
      })
      .catch(errToast)
  }

  handleSort = (column) => {
    const { keys, ascending } = this.state
    if (column === 'tenant.name') {
      this.setState({ keys: sort(keys, column, !ascending), ascending: !ascending, column })
    } else if (column === 'description') {
      this.setState({ keys: sortDescription(keys, column, !ascending), ascending: !ascending, column })
    }
  }

  checkSorted = (selectedColumn) => {
    const { column, ascending } = this.state

    if (column !== selectedColumn) return
    return ascending
      ? 'ascending'
      : 'descending'
  }

  render () {
    const { user, keys } = this.state

    return (
      <UserApiKeys
        user={user}
        keys={keys}
        handleTenantSelection={this.onTenantSelection}
        handleCreateKey={this.onCreateKey}
        handleUpdateKey={this.onUpdateKey}
        handleDeleteKey={this.onDeleteKey}
        handleCancelDeleteKey={this.onCancelDeleteKey}
        onHandleSort={this.handleSort}
        checkSorted={this.checkSorted}
      />
    )
  }
}

export default ApiKeys
