import { permissions as actionTypes } from '../actions/types'

export default (concepts = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONCEPTS:
      return [].concat(action.concepts)
    default:
      return concepts
  }
}
