import React from 'react'
import { Header, Grid, Segment } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import { jwtDecode } from 'jwt-decode'

const COLORS = {
  KEY: '#004175',
  DETAILS: '#333333'
}

export default ({ license }) => {
  if (isEmpty(license)) return null
  const licenseToken = jwtDecode(license.key)

  return (
    <div>
      <Grid divided='vertically'>
        <Grid.Row columns={1} style={{ marginTop: '20px' }}>
          <Grid.Column>
            <Segment.Group>
              <Header as='h5' attached='top' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Key</span>
              </Header>
              <Segment attached>
                <code style={{ wordWrap: 'break-word', fontSize: '1.1em' }}>
                  <span style={{ color: COLORS.KEY }}>{license.key}</span>
                </code>
              </Segment>
              <Header as='h5' attached>Details</Header>
              <Segment attached>
                <pre>
                  <code style={{ color: COLORS.DETAILS }}>
                    {JSON.stringify(licenseToken, null, 2)}
                  </code>
                </pre>
              </Segment>
            </Segment.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}
