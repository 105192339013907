import React, { Component } from 'react'
import { Header, Icon } from 'semantic-ui-react'
import LicensesTable from '../../components/licenses/list'
import { errToast } from '../../lib/toast'
import authClient from '../../lib/client'
import LicenseUploader from '../../components/licenses/uploader'
class Licenses extends Component {
  constructor (props) {
    super(props)
    this.state = { licenses: [] }
  }

  async componentDidMount () {
    await this.fetchLicenses()
  }

  async fetchLicenses () {
    try {
      const licenses = await authClient.getLicensesSummaries()
      this.setState({ licenses })
    } catch (err) {
      errToast({ message: err.error ?? err.message })
    }
  }

  async uploadLicense (licenseKey) {
    await authClient.uploadLicense(licenseKey)
    await this.fetchLicenses()
  }

  render () {
    const { licenses } = this.state
    return (
      <>
        <div id='top-bar'>
          <Header>
            <Icon name='shield' />{licenses.length} Licenses
          </Header>
          <LicenseUploader handleUpload={(licenseKey) => this.uploadLicense(licenseKey)} />
        </div>
        <LicensesTable licenses={licenses} />
      </>
    )
  }
}

export default Licenses
