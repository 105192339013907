import React, { Component } from 'react'
import { isEmpty } from 'lodash'
import { Button, Form, Image, Message, Segment } from 'semantic-ui-react'

import './login.css'
const logo = 'https://static.waylay.io/logos/waylay/waylay-vector-logo.svg'

const ssoBtnStyles = {
  marginTop: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

class LoginForm extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  updateForm (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  submitForm (event) {
    event.preventDefault()
    const { username, password } = this.state
    this.props.onSubmit({ username, password })
  }

  render () {
    return (
      <div>
        <Form onSubmit={e => this.submitForm(e)} className='large'>
          <Segment>
            <Image centered src={logo} className='logo' alt='Waylay' />
            <Form.Field>
              <Form.Input
                label='Username'
                name='username'
                required
                placeholder='Email'
                onChange={e => this.updateForm(e)}
                type='email'
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                label='Password'
                name='password'
                type='password'
                required
                placeholder='Password'
                onChange={e => this.updateForm(e)}
              />
            </Form.Field>
            <Button
              primary fluid size='large' type='submit'
              loading={this.props.loading}
              disabled={this.props.loading}
              content='Sign In'
            />
            {this.renderSSOButtons()}
          </Segment>
          <Message
            negative={Boolean(this.props.error)}
            hidden={!this.props.error}
            content={this.props.error}
          />
        </Form>
      </div>
    )
  }

  renderSSOButtons () {
    const { ssoProviders } = this.props
    const { oauth2 } = ssoProviders

    if (isEmpty(oauth2)) return null

    return oauth2.map(config => {
      const { authorizeUrl, icon, name } = config
      return (
        <Button
          fluid basic
          key={name}
          size='large'
          as='a'
          href={authorizeUrl}
          style={ssoBtnStyles}
        >
          <Image style={{ height: 20, marginRight: 5 }} src={icon} />
          Sign In with {name}
        </Button>
      )
    })
  }
}
export default LoginForm
