import React from 'react'
import { upperFirst } from 'lodash'
import { Icon, Label } from '@waylay/react-components'

export default (props) => {
  const { status } = props

  const colorMap = {
    enabled: 'green',
    disabled: 'red'
  }

  const iconMap = {
    enabled: 'done',
    disabled: 'block',
    unverified: 'help_outline'
  }

  const statusLabel = (
    <Label outline>
      <Icon color={colorMap[status]} name={iconMap[status]} />&nbsp;{upperFirst(status)}
    </Label>
  )

  return statusLabel
}
