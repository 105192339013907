import React, { Component } from 'react'
import { Header, Icon, Container, Segment } from 'semantic-ui-react'
import { Button } from '@waylay/react-components'
import { isEmpty } from 'lodash'

import RadioSubject from './subject'
import Expires from './expire'
import UsersDropdown from '../../containers/userDropdown'
import TenantsDropdown from '../../containers/tenantDropdown'
import ApplicationsDropdown from '../../containers/applicationsDropdown'
import PermissionsDropdown from '../../containers/permissionsDropdown'
import { applications } from '../../actions'
import { errToast } from '../../lib/toast'
import Jwt from './jwt'

const { fetchApplication } = applications

const DROPDOWN_STYLES = {
  marginRight: '12px',
  marginTop: '12px',
  paddingTop: '12px'
}

class TokenGenerator extends Component {
  constructor (props) {
    super(props)
    this.state = {
      subject: 'user',
      tenant: undefined,
      user: undefined,
      application: undefined,
      applicationSecret: undefined,
      permissions: [],
      expiresIn: ''
    }
  }

  handleSubjectChange = (subject) => {
    this.setState({ subject })
  }

  handleUserChange = (user) => {
    this.setState({ user })
  }

  handleTenantChange = (tenant) => {
    this.setState({ tenant })
  }

  handleApplicationsChange = (appId) => {
    fetchApplication(appId)
      .then(({ application }) => {
        const { id, secret, tenants: appTenants } = application

        const app = { application: id, applicationSecret: secret }

        // If app has no tenants, set all tenants as option
        isEmpty(appTenants)
          ? this.setState({ ...app, tenants: undefined })
          : this.setState({ ...app, tenants: appTenants })
      })
      .catch(errToast)
  }

  handlePermissionsChange = (permissions) => {
    this.setState({ permissions })
  }

  handleExpiryChange = (expiresIn) => {
    this.setState({ expiresIn })
  }

  onSubmit = () => {
    const { handleSubmit } = this.props
    handleSubmit(this.state)
  }

  render () {
    const { jwt } = this.props
    return (
      <div>
        <div id='top-bar'>
          <Header>
            <Icon name='key' />Token Generator
          </Header>
        </div>
        <Segment padded>
          <RadioSubject
            subject={this.state.subject}
            onChange={this.handleSubjectChange}
          />
          {this.renderDropdowns()}
          <Button onClick={this.onSubmit} style={{ marginTop: 10 }}>Generate JWT</Button>
          <Container style={{ marginTop: 15 }}>
            <Jwt jwt={jwt} />
          </Container>
        </Segment>
      </div>
    )
  }

  renderDropdowns () {
    const { subject } = this.state
    const isUser = subject === 'user' || subject === ''

    return isUser
      ? this.renderUserDropdowns()
      : this.renderApplicationDropdowns()
  }

  renderUserDropdowns () {
    return (
      <div>
        <span style={DROPDOWN_STYLES}>
          <TenantsDropdown
            fluid
            onTenantSelected={this.handleTenantChange}
          />
        </span>
        <span style={DROPDOWN_STYLES}>
          <UsersDropdown fluid onChange={this.handleUserChange} />
        </span>
      </div>
    )
  }

  renderApplicationDropdowns () {
    const { tenants } = this.state
    return (
      <div>
        <span style={DROPDOWN_STYLES}>
          <ApplicationsDropdown
            fluid
            onApplicationSelected={this.handleApplicationsChange}
          />
        </span>
        <span style={DROPDOWN_STYLES}>
          <TenantsDropdown
            fluid
            tenants={tenants}
            onTenantSelected={this.handleTenantChange}
          />
        </span>
        <span style={DROPDOWN_STYLES}>
          <PermissionsDropdown
            fluid multiple
            onPermissionsSelected={this.handlePermissionsChange}
          />
        </span>
        <span style={DROPDOWN_STYLES}>
          <Expires onExpiryChange={this.handleExpiryChange} />
        </span>
      </div>
    )
  }
}

export default TokenGenerator
