import client from '../lib/client'
import { users as types } from './types'

function clearUsers () {
  return {
    type: types.CLEAR_USER
  }
}

function fetchUser (id) {
  return client.user(id).then((user) => ({
    type: types.FETCH_USER,
    user
  }))
}

function fetchServiceUsers () {
  return client.users({ loginEnabled: false }).then((users) => ({
    type: types.FETCH_USERS,
    users
  }))
}

function fetchUsers () {
  return client.users({ loginEnabled: true }).then((users) => ({
    type: types.FETCH_USERS,
    users
  }))
}

async function fetchUsersByTenant (tenant, isServiceUser) {
  return client
    .tenant(tenant, { loginEnabled: !isServiceUser })
    .then(({ users }) => ({
      type: types.FETCH_USERS,
      users
    }))
}

function createUser (user) {
  return client.createUser(user).then((user) => ({
    type: types.CREATED_USER,
    user
  }))
}

function updateUser (userId, updateValues) {
  return client.updateUser(userId, updateValues).then((user) => ({
    type: types.UPDATED_USER,
    user
  }))
}

function unlockUser (userId) {
  return client.unlockUser(userId).then(() => ({
    type: types.UNLOCK_USER
  }))
}

function deleteUser (id, force = true) {
  return client.deleteUser(id, { force }).then((user) => ({
    type: types.DELETED_USER,
    id
  }))
}

function fetchUserKeys (id) {
  return client.userWithAPIKeys(id).then((keys) => ({
    type: types.FETCH_USER_KEYS_TENANTS,
    keys
  }))
}

function addRoleToUser (userId, roleId) {
  return client.addRoleToUser(userId, roleId).then((role) => ({
    type: types.ROLE_USER_ADDED,
    role
  }))
}

function removeRoleFromUser (userId, roleId) {
  return client.removeRoleFromUser(userId, roleId).then(() => ({
    type: types.ROLE_USER_REMOVED,
    role: { userId, roleId }
  }))
}

export default {
  fetchUser,
  clearUsers,
  fetchUsers,
  fetchUsersByTenant,
  fetchServiceUsers,
  createUser,
  updateUser,
  unlockUser,
  deleteUser,
  fetchUserKeys,
  addRoleToUser,
  removeRoleFromUser
}
