import React, { Component } from 'react'

import UserPermissions from '../../components/users/permissions'

class Permissions extends Component {
  constructor (props) {
    super(props)
    this.state = { permissions: undefined }
  }

  render () {
    const { permissions } = this.props
    if (!permissions) return null

    return <UserPermissions permissions={permissions} />
  }
}

export default Permissions
