import client from '../lib/client'
import { permissions as types } from './types'

function fetchPermission (id) {
  return client.permission(id)
    .then(permission => ({
      type: types.FETCH_PERMISSION,
      permission
    }))
}

function fetchPermissions () {
  return client.permissions()
    .then(permissions => ({
      type: types.FETCH_PERMISSIONS,
      permissions
    }))
}

function fetchPermissionsConcepts () {
  return client.permissionsConcepts()
    .then(concepts => ({
      type: types.FETCH_CONCEPTS,
      concepts
    }))
}

function createPermissionConcept (details) {
  return client.createPermissionConcept(details)
    .then(permission => ({
      type: types.PERMISSION_CREATED,
      permission
    }))
}

function removePermissionConcept (name) {
  return client.removePermissionConcept(name)
    .then(() => ({
      type: types.PERMISSION_REMOVED,
      name
    }))
}

export default {
  fetchPermission,
  fetchPermissions,

  fetchPermissionsConcepts,
  createPermissionConcept,
  removePermissionConcept
}
