import React, { Component } from 'react'
import { get } from 'lodash'

import TokenGenerator from '../../components/tokens/tokens'
import { errToast } from '../../lib/toast'
import { tokens } from '../../actions'

const { createUserToken, createApplicationToken } = tokens

class Tokens extends Component {
  constructor (props) {
    super(props)
    this.state = { jwt: undefined }
  }

  onSubmit = (details) => {
    // Reset JWT
    this.setState({ jwt: undefined })

    const { subject } = details
    subject === 'user'
      ? this.handleUserToken(details)
      : this.handleApplicationToken(details)
  }

  handleUserToken ({ tenant, user }) {
    if (!tenant || !user) return

    const req = createUserToken(tenant, user)
    this.handleTokenRequest(req)
  }

  handleApplicationToken ({ tenant, application, applicationSecret, permissions, expiresIn }) {
    if (!tenant || !application) return

    const req = createApplicationToken(application, applicationSecret, tenant, { permissions, expiresIn })
    this.handleTokenRequest(req)
  }

  handleTokenRequest (tokenReq) {
    tokenReq
      .then(response => {
        const jwt = get(response, ['token', 'token'])
        this.setState({ jwt })
      })
      .catch(errToast)
  }

  render () {
    const { jwt } = this.state
    return (
      <TokenGenerator
        jwt={jwt}
        handleSubmit={this.onSubmit}
      />
    )
  }
}

export default Tokens
