import React, { Component } from 'react'
import { withRouter } from '../../common/withRouter'
import { toast } from 'react-toastify'

import NewApplicationForm from '../../components/applications/new'
import { errToast } from '../../lib/toast'
import { applications } from '../../actions'

const { createApplication } = applications

class NewApplication extends Component {
  onSubmit = (formState) => {
    createApplication(formState)
      .then(({ application }) => {
        const { name } = application
        toast.success(`Application created: ${name}`)
        return application
      })
      .then(app => {
        const { history } = this.props
        history(`/apps/${app.id}/details`, { replace: false })
      })
      .catch(errToast)
  }

  render () {
    return <NewApplicationForm {...this.props} handleSubmit={this.onSubmit} />
  }
}

export default withRouter(NewApplication)
