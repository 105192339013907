import React, { Component, Fragment } from 'react'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ConfirmationModal from '../confirmationModal'
import sort from '../../lib/sort'
import { get } from 'lodash'
import { Table } from '@waylay/react-components'
import TableHeaderCellBold from '../../common/tableHeaderCellBold'

class CustomTable extends Component {
  constructor (props) {
    super(props)
    this.state = { roles: [], column: 'name', ascending: true }
  }

  componentDidMount () {
    const { ascending } = this.state
    const roles = sort(this.props.roles, 'name', ascending)
    this.setState({ roles })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const { ascending } = this.state
    const roles = sort(nextProps.roles, 'name', ascending)
    this.setState({ roles })
  }

  handleSort (column) {
    const { roles, ascending } = this.state
    this.setState({ roles: sort(roles, column, !ascending), ascending: !ascending, column })
  }

  checkSorted (selectedColumn) {
    const { column, ascending } = this.state
    if (column === selectedColumn) {
      if (ascending) return 'ascending'
      return 'descending'
    }
  }

  render () {
    const { roles } = this.state
    const { predefined, modal } = this.props

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <TableHeaderCellBold
              sorted={this.checkSorted('name')}
              onClick={() => this.handleSort('name')}
            >
              Name
            </TableHeaderCellBold>
            <TableHeaderCellBold
              sorted={this.checkSorted('description')}
              onClick={() => this.handleSort('description')}
            >
              Description
            </TableHeaderCellBold>
            {
              // Predefined roles don't belong to a tenant or have actions
              !predefined &&
                <>
                  <TableHeaderCellBold>Tenant</TableHeaderCellBold>
                  <TableHeaderCellBold style={{ width: 120 }}>Actions</TableHeaderCellBold>
                </>
            }
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {roles.map(role => renderRole(role, modal, predefined))}
        </Table.Body>
      </Table>
    )
  }
}

function renderRole (role, modal, predefined) {
  const { id, tenant_id: tenantId, name, description } = role

  return (
    <Table.Row key={`${id}-${tenantId}`}>
      <Table.Cell>
        <Link to={`/roles/${id}/details`}>{name}</Link>
      </Table.Cell>
      <Table.Cell>{description}</Table.Cell>
      <AdditionalInfo {...role} modal={modal} tablePredefined={predefined} />
    </Table.Row>
  )
}

const AdditionalInfo = ({ id, name, tenant, tenant_id: tenantId, tablePredefined, modal }) => {
  if (tablePredefined) return null

  const domain = get(tenant, 'domain', '')
  return (
    <>
      <Table.Cell>{domain || tenantId}</Table.Cell>
      <Table.Cell>
        <Button.Group basic size='tiny'>
          <ConfirmationModal
            icon={modal.icon}
            action={modal.action}
            header={modal.header}
            subject={name}
            onConfirm={modal.onConfirm(id, tenantId)}
          />
        </Button.Group>
      </Table.Cell>
    </>
  )
}

export default CustomTable
