import { permissions as actionTypes } from '../actions/types'
import { reject } from 'lodash'

export default (permissions = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_PERMISSIONS:
      return [].concat(action.permissions)
    case actionTypes.PERMISSION_CREATED:
      return [].concat(permissions.push(action))
    case actionTypes.PERMISSION_REMOVED:
      return reject(permissions, { id: action.id })
    default:
      return permissions
  }
}
