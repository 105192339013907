import React from 'react'
import { Link } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'
import LoginForm from './form'

import './login.css'

export default ({ loading, error, ssoProviders, handleSubmit }) => (
  <div className='login__wrapper'>
    <LoginForm
      loading={loading}
      error={error}
      ssoProviders={ssoProviders}
      onSubmit={e => handleSubmit(e)}
    />
    <Segment vertical textAlign='center'>
      <Link to='forgot'>Forgot your password?</Link>
    </Segment>
  </div>
)
