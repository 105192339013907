import { users as actionTypes } from '../actions/types'
import { reject } from 'lodash'

export default function users (users = [], action) {
  switch (action.type) {
    case actionTypes.FETCH_USERS:
      return [].concat(action.users)
    case actionTypes.DELETED_USER:
      return reject(users, { id: action.id })
    case actionTypes.CLEAR_USER:
      return []
    default:
      return users
  }
}
