import React, { Component } from 'react'
import queryString from 'querystring'
import url from 'url'
import { get } from 'lodash'
import { withRouter } from '../../common/withRouter'
import ResetPasswordScreen from '../../components/password/resetPassword'

import { auth } from '../../actions'
const { resetPassword, checkToken } = auth
class ResetPassword extends Component {
  constructor (props) {
    super(props)
    this.state = { message: undefined, loading: true }
    this.token = get(this.props, ['match', 'params', 'token'])
  }

  componentDidMount () {
    this.setState({ loading: true })
    checkToken(this.token)
      .then(() => this.setState({ loading: false }))
      .catch(() => {
        this.setState({
          error: (
            <div>
              <p>Token has either expired or has already been used.</p>
              <a href='/forgot'>Reset password again.</a>
            </div>
          ),
          loading: false
        })
      })
  }

  onSubmit (newPassword) {
    resetPassword(this.token, newPassword)
      .then(reset => {
        const referer = this.getRefererUrl()
        this.setState({ message: 'Your password has been updated.', error: null, success: true, referer })
      })
      .catch(err => {
        const msg = get(err, 'response.data.message', err.message)
        this.setState({ error: msg, success: false })
      })
  }

  getRefererUrl () {
    const qs = get(this.props, ['location', 'search'], '?').replace('?', '')
    const { referer } = queryString.parse(qs)
    return referer
      ? url.format({ protocol: 'https', host: referer })
      : null
  }

  render () {
    return (
      <ResetPasswordScreen {...this.state} onSubmit={this.onSubmit.bind(this)} />
    )
  }
}

export default withRouter(ResetPassword)
