import React from 'react'
import { Segment, Icon } from 'semantic-ui-react'

const containerStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh'
}

const iconStyles = {
  verticalAlign: 'inherit',
  fontSize: '1em',
  margin: 0
}

export default () => (
  <div style={containerStyles}>
    <Segment>
      <Icon loading name='spinner' size='large' style={iconStyles} />
      <span style={{ marginLeft: 8 }}>Hold on, you are being redirected to the login page...</span>
    </Segment>
  </div>
)
