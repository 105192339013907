import React, { Component } from 'react'
import { Button, Form, Image, Message, Segment } from 'semantic-ui-react'

import '../login/login.css'
const logo = 'https://static.waylay.io/logos/waylay/waylay-vector-logo.svg'

class PasswordForm extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  updateForm (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  submitForm (event) {
    event.preventDefault()
    const { newPassword } = this.state

    this.props.onSubmit(newPassword)
  }

  render () {
    const { error, success, loading, referer } = this.props
    const message = error || this.props.message

    return (
      <div>
        <Form onSubmit={e => this.submitForm(e)} className='large'>
          {(!error && success === undefined) && (
            <Segment>
              <Image centered src={logo} className='logo' alt='Waylay' />
              <Form.Field>
                <Form.Input
                  label='New Password'
                  name='newPassword'
                  placeholder='New Password'
                  type='password'
                  required
                  onChange={e => this.updateForm(e)}
                />
              </Form.Field>
              <Button
                primary fluid size='large' type='submit'
                loading={loading}
                disabled={loading}
                content='Save'
              />
            </Segment>
          )}
          <Message
            positive={Boolean(success)} negative={Boolean(error)}
            hidden={!message}
            content={message}
          />
          {this.renderGoBack(referer)}
        </Form>
      </div>
    )
  }

  renderGoBack (url) {
    if (!url) return null
    const redirectToUrl = (e) => {
      e.preventDefault()
      window.location.href = url
    }

    return (
      <Button fluid onClick={redirectToUrl}>Go back</Button>
    )
  }
}

export default PasswordForm
