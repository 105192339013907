import React, { Component } from 'react'
import { Button, Form, Header, Icon, Segment, TextArea } from 'semantic-ui-react'
import Breadcrumb from '../breadcrumb'

class SettingsForm extends Component {
  constructor (props) {
    super(props)

    this.state = { key: '', value: '' }
  }

  handleKeyChange (event) {
    this.setState({ key: event.target.value })
  }

  handleValueChange (event) {
    this.setState({ value: event.target.value })
  }

  submit (event) {
    event.preventDefault()

    const { key, value } = this.state
    this.props.onSubmit({ [key]: value })
  }

  render () {
    return (
      <div>
        <Breadcrumb />

        <Segment style={{ width: 460, margin: '0 auto', marginTop: 30 }}>
          <div id='top-bar'>
            <Header>New Setting</Header>
            <Icon name='add' />
          </div>
          <Form onSubmit={e => this.submit(e)}>
            <Form.Field>
              <label>Key</label>
              <input placeholder='Key' value={this.state.key} onChange={e => this.handleKeyChange(e)} />
            </Form.Field>
            <Form.Field>
              <label>Value</label>
              <TextArea placeholder='Value' autoheight='true' value={this.state.value} onChange={e => this.handleValueChange(e)} />
            </Form.Field>
            <Button type='submit'>Submit</Button>
          </Form>
        </Segment>
      </div>
    )
  }
}

export default SettingsForm
