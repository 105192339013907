import React, { Component } from 'react'
import { withRouter } from '../../common/withRouter'
import { toast } from 'react-toastify'

import NewRoleForm from '../../components/roles/new'
import { errToast } from '../../lib/toast'
import { roles } from '../../actions'

const { createRole } = roles

class NewRoleContainer extends Component {
  onSubmit = (formState) => {
    createRole(formState)
      .then(({ role }) => {
        const { name } = role
        toast.success(`Role created: ${name}`)
        return role
      })
      .then(role => {
        const { id } = role
        const { history } = this.props
        history(`/roles/${id}/details`, { replace: false })
      })
      .catch(errToast)
  }

  render () {
    return <NewRoleForm {...this.props} handleSubmit={this.onSubmit} />
  }
}

export default withRouter(NewRoleContainer)
