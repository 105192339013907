import { useLocation, useNavigate, useParams } from 'react-router'

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate()
    const location = useLocation()
    const params = useParams()
    return <Component history={history} location={location} match={{ params }} {...props} />
  }

  return Wrapper
}
