import React, { Component } from 'react'
import { Dropdown, Button } from 'semantic-ui-react'
import { differenceWith, get, flatMap } from 'lodash'
import Roles from '../roles/table'
import TenantsDropdown from '../tenantsDropdown'

export default ({ user = {}, customRoles = [], predefinedRoles = [], onAddRole, modal }) => {
  const { tenants = [], roles = [] } = user

  const roleDropdownOptions = processRoles(customRoles, predefinedRoles, roles)

  // Split predefined roles per tenant + add full tenant object to every role
  const allUserRoles = flatMap(roles, role => {
    const isPredefined = predefinedRoles.some(r => r.id === role.id)

    const tenantIds = [].concat(get(role, 'tenant_ids') || get(role, 'tenant_id'))

    return tenantIds.map(tenantId => {
      const tenant = tenants.find(t => t.id === tenantId)
      return { ...role, tenant_id: tenantId, tenant, predefined: isPredefined }
    })
  })

  return (
    <span>
      <div id='top-bar' style={{ justifyContent: 'flex-end' }}>
        <AddRole roles={roleDropdownOptions} tenants={tenants} onSubmit={onAddRole} />
      </div>
      <Roles roles={allUserRoles} modal={modal} />
    </span>
  )
}

function processRoles (customRoles = [], predefinedRoles = [], userRoles = []) {
  // Predefined can be assigned multiple times
  const unassignedCustomRoles = differenceWith(customRoles, userRoles, (obj, other) => obj.id === other.id)

  const allRoles = [].concat(unassignedCustomRoles, predefinedRoles)

  const dropdownOptions = allRoles.map(parseRoleToDropdownOption)
  dropdownOptions.unshift({ key: null, text: 'No role', value: [] })

  return dropdownOptions
}

function parseRoleToDropdownOption (role = {}) {
  const { predefined } = role
  const labelColor = predefined ? 'blue' : 'red'

  return {
    key: role.id,
    text: role.name,
    value: [role.id, role.predefined],
    label: { color: labelColor, empty: true, circular: true }
  }
}

class AddRole extends Component {
  handleRoleSelect = (_, { value }) => {
    this.setState({ role: value })
  }

  handleTenantSelect = value => {
    this.setState({ tenant: value })
  }

  handleSubmit = () => {
    const { tenant } = this.state
    const [role] = get(this.state, 'role')
    const { onSubmit } = this.props

    typeof onSubmit === 'function' && onSubmit({ role, tenant })
  }

  render () {
    const [, isPredefined] = get(this.state, 'role', [undefined, false])
    const { roles = [], tenants = [] } = this.props

    return (
      <>
        <Dropdown
          selection
          search
          placeholder='Select a role'
          options={roles}
          selectOnBlur={false}
          onChange={this.handleRoleSelect}
        />
        {isPredefined && (
          <TenantsDropdown
            tenants={tenants}
            onTenantSelected={this.handleTenantSelect}
            style={{ marginLeft: 5 }}
          />
        )}
        <Button onClick={this.handleSubmit} style={{ marginLeft: 5 }}>Add role</Button>
      </>
    )
  }
}
