import client from '../lib/client'
import { tokens as types } from './types'

function createUserToken (tenantId, userId) {
  return client.createUserToken(tenantId, userId)
    .then(token => ({
      type: types.CREATE_USER_TOKEN,
      token
    }))
}

function createApplicationToken (applicationId, applicationSecret, userId, options) {
  return client.createTokenForApplication(applicationId, applicationSecret, userId, options)
    .then(token => ({
      type: types.CREATE_APPLICATION_TOKEN,
      token
    }))
}

export default {
  createUserToken,
  createApplicationToken
}
