import client from '../lib/client'
import { tenants as types } from './types'

function fetchTenant (id) {
  return client.tenant(id)
    .then(tenant => ({
      type: types.FETCH_TENANT,
      tenant
    }))
}

function fetchTenants () {
  return client.tenants()
    .then(tenants => ({
      type: types.FETCH_TENANTS,
      tenants
    }))
}

function createTenant (tenant) {
  return client.createTenant(tenant)
    .then(tenant => ({
      type: types.CREATED_TENANT,
      tenant
    }))
}

function updateTenant (id, state) {
  return client.updateTenant(id, state)
    .then(() => ({
      type: types.UPDATED_TENANT,
      id
    }))
}

function removeTenant (id) {
  return client.removeTenant(id)
    .then(() => ({
      type: types.DELETED_TENANT,
      id
    }))
}

function addUserToTenant (userId, tenantId) {
  return client.addUserToTenant(userId, tenantId)
    .then(() => ({
      type: types.USER_ADDED_TO_TENANT,
      id: userId
    }))
}

function removeUserFromTenant (userId, tenantId) {
  return client.removeUserFromTenant(userId, tenantId)
    .then(() => ({
      type: types.USER_REMOVED_FROM_TENANT,
      id: userId
    }))
}

function createTenantSetting (tenantId, settings) {
  return client.createTenantSetting(tenantId, settings)
    .then(tenant => ({
      type: types.CREATED_TENANT_SETTING,
      tenant
    }))
}

function deleteTenantSetting (tenantId, key) {
  return client.deleteTenantSetting(tenantId, key)
    .then(() => ({
      type: types.DELETED_TENANT_SETTING,
      id: tenantId,
      key
    }))
}

export default {
  fetchTenant,
  fetchTenants,

  createTenant,
  updateTenant,
  removeTenant,

  addUserToTenant,
  removeUserFromTenant,

  createTenantSetting,
  deleteTenantSetting
}
