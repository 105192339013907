import React, { Component } from 'react'
import { Form, Input, Popup, Button, Message } from 'semantic-ui-react'

export default class Expires extends Component {
  state = { value: '', error: false }

  handleChange = (e, { value }) => {
    this.props.onExpiryChange(value)
  }

  render () {
    return (
      <Form style={{ marginTop: 5 }} error>
        <Form.Field>
          Add expiry date
        </Form.Field>
        <Form.Field>
          <Input
            style={{ width: '150px' }}
            onChange={this.handleChange}
          />
          <Popup
            trigger={<Button style={{ marginLeft: '10px' }} icon='info' />}
            content='Format: 1h / 5m / 2 days / 2.5 hrs / 2.5 days '
          />
          {this.renderError()}
        </Form.Field>
      </Form>
    )
  }

  renderError () {
    if (this.state.error) {
      return (
        <Message
          error
          header='Must match format'
          content='The expiry date must match a format like: 1h / 5m / 2 days / 2.5 hrs / 2.5 days'
        />
      )
    }
  }
}
