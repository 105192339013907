import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { isAuthenticated } from '../lib/auth'

import Layout from '../components/layout'
import Users from './users/users'
import UserDetail from './users/detail'
import NewUser from './users/new'
import EditUser from './users/edit'
import Tenants from './tenants/tenants'
import TenantDetail from './tenants/detail'
import NewTenant from './tenants/new'
import EditTenant from './tenants/edit'
import NewSettingTenant from './tenants/newSetting'
import Roles from './roles/roles'
import RolesDetail from './roles/detail'
import Permissions from './permissions/permissions'
import Applications from './applications/applications'
import ApplicationDetail from './applications/detail'
import NewApplication from './applications/new'

import GroupsList from './groups/list'
import GroupDetail from './groups/detail'
import NewGroup from './groups/new'
import EditGroup from './groups/edit'

import CompaniesList from './companies/list'
import CompanyDetail from './companies/detail'
import NewCompany from './companies/new'
import EditCompany from './companies/edit'

import MeContainer from './me'
import NewRole from './roles/new'
import NewPermissionConcept from './permissions/newPermissionConcept'
import Tokens from './tokens/tokens'
import Licenses from './licenses/list'
import LicenseDetail from './licenses/detail'

export default () => {
  if (!isAuthenticated()) return <Navigate to='/login' />
  return (
    <div>
      <Layout>
        <div>
          <Routes>
            <Route path='/users' element={<Users />} />
            <Route path='/serviceUsers' element={<Users />} />
            <Route path='/users/new' element={<NewUser />} />
            <Route path='/serviceUsers/new' element={<NewUser />} />
            <Route path='/users/:id/edit' element={<EditUser />} />
            <Route path='/serviceUsers/:id/edit' element={<EditUser />} />
            <Route path='/users/:id/*' element={<UserDetail />} />
            <Route path='/serviceUsers/:id/*' element={<UserDetail />} />
            <Route path='/groups' element={<GroupsList />} />
            <Route path='/groups/new' element={<NewGroup />} />
            <Route path='/groups/:id/edit' element={<EditGroup />} />
            <Route path='/groups/:id' element={<GroupDetail />} />
            <Route path='/apps' element={<Applications />} />
            <Route path='/apps/new' element={<NewApplication />} />
            <Route path='/apps/:id/*' element={<ApplicationDetail />} />
            <Route path='/tenants' element={<Tenants />} />
            <Route path='/tenants/new' element={<NewTenant />} />
            <Route path='/tenants/:id/settings/new' element={<NewSettingTenant />} />
            <Route path='/tenants/:id/edit' element={<EditTenant />} />
            <Route path='/tenants/:id/*' element={<TenantDetail />} />
            <Route path='/roles' element={<Roles />} />
            <Route path='/roles/custom' element={<Roles tab='custom' />} />
            <Route path='/roles/predefined' element={<Roles tab='predefined' />} />
            <Route path='/roles/new' element={<NewRole />} />
            <Route path='/roles/:id/*' element={<RolesDetail />} />
            <Route path='/me' element={<MeContainer />} />
            <Route path='/permissions' element={<Permissions />} />
            <Route path='/permissions/new' element={<NewPermissionConcept />} />
            <Route path='/licenses' element={<Licenses />} />
            <Route path='/licenses/:id/*' element={<LicenseDetail />} />
            <Route path='/tokens' element={<Tokens />} />
            <Route path='/companies' element={<CompaniesList />} />
            <Route path='/companies/new' element={<NewCompany />} />
            <Route path='/companies/:id/edit' element={<EditCompany />} />
            <Route path='/companies/:id' element={<CompanyDetail />} />
            <Route path='/' element={<Navigate to='/users' replace />} />
          </Routes>
        </div>
      </Layout>
      <ToastContainer autoClose={5000} position='bottom-right' />
    </div>
  )
}
