import React, { Component } from 'react'
import { withRouter } from '../../common/withRouter'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Header, Icon } from 'semantic-ui-react'
import { Button } from '@waylay/react-components'

import Table from '../../components/roles/table'
import Tab from 'semantic-ui-react/dist/commonjs/modules/Tab/Tab'
import { errToast } from '../../lib/toast'
import { roles, predefinedRoles } from '../../actions'

const { fetchRoles, deleteRole } = roles
const { fetchPredefinedRoles } = predefinedRoles

class Roles extends Component {
  constructor (props) {
    super(props)
    this.state = { roles: undefined, predefinedRoles: undefined }
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData () {
    fetchRoles()
      .then(({ roles }) => this.setState({ roles }))
      .catch(errToast)

    fetchPredefinedRoles()
      .then(({ predefinedRoles }) => this.setState({ predefinedRoles }))
      .catch(errToast)
  }

  deleteRole = (roleId) => {
    deleteRole(roleId)
      .then(() => {
        this.fetchData()
        toast.warn('Role successfully deleted')
      })
      .catch(errToast)
  }

  getModal () {
    return {
      header: 'Delete role',
      onConfirm: (id) => () => this.deleteRole(id)
    }
  }

  render () {
    const { roles, predefinedRoles } = this.state
    if (!roles || !predefinedRoles) return null

    const modal = this.getModal()

    const { tab = 'predefined', history } = this.props
    const panes = [
      {
        menuItem: {
          key: 'predefined',
          content: 'Predefined roles',
          onClick: () => history('/roles/predefined', { replace: true })
        },
        render: () => {
          const roleCount = predefinedRoles.length
          return (
            <Tab.Pane>
              <div id='top-bar'>
                <Header>
                  <Icon name='users' />{roleCount} {roleCount === 1 ? 'Role' : 'Roles'}
                </Header>
              </div>
              <Table predefined roles={predefinedRoles} modal={modal} />
            </Tab.Pane>
          )
        }
      },
      {
        menuItem: {
          key: 'custom',
          content: 'Custom roles',
          onClick: () => history('/roles/custom', { replace: true })
        },
        render: () => {
          const roleCount = roles.length
          return (
            <Tab.Pane>
              <div id='top-bar'>
                <Header>
                  <Icon name='users' />{roleCount} {roleCount === 1 ? 'Role' : 'Roles'}
                </Header>
                <NavLink to='/roles/new' style={{ marginLeft: 20 }}>
                  <Button>
                    <Icon name='add' />Add custom role
                  </Button>
                </NavLink>
              </div>
              <Table roles={roles} modal={modal} />
            </Tab.Pane>
          )
        }
      }
    ]
    const defaultActiveIndex = getActiveTabIndex(panes, tab)

    return (
      <div>
        <Tab panes={panes} defaultActiveIndex={defaultActiveIndex} />
      </div>
    )
  }
}

export default withRouter(Roles)

function getActiveTabIndex (tabs, active) {
  return tabs.findIndex(pane => pane.menuItem.key === active)
}
