import React from 'react'
import moment from 'moment'
import { Table } from 'semantic-ui-react'

export default (props) => {
  const { name, description, created_at: createdAt, update_at: updatedAt, id } = props.company
  if (!props.company) return null

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{name}</Table.HeaderCell>
          <Table.HeaderCell>{id}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Description</Table.Cell>
          <Table.Cell>{description}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Created At</Table.Cell>
          <Table.Cell>{moment(createdAt).format('LLL')}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Updated At</Table.Cell>
          <Table.Cell>{moment(updatedAt).format('LLL')}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}
