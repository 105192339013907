import React from 'react'
import { Button, Modal, Header, Icon } from 'semantic-ui-react'

const bodyStyles = { paddingTop: 20, paddingLeft: 20 }

export default ({ icon = 'trash', action = 'Delete', trigger, header, subject, onConfirm }) => (
  <Modal
    trigger={getTrigger(icon, action, trigger)}
    header={getHeader(icon, header)}
    content={getBody(action, subject)}
    actions={getActions(icon, action, onConfirm)}
  />
)

function getTrigger (icon, content, trigger) {
  return trigger || (
    <Button basic color='red' size='mini'>
      <Icon name={icon} /> {content}
    </Button>
  )
}

function getHeader (icon, header) {
  return <Header icon={icon} content={header} />
}

function getBody (action, subject) {
  return (
    <p style={bodyStyles}>
      <span>Are you sure you want to {action.toLowerCase()} <strong>{subject}</strong>?</span>
    </p>
  )
}

function getActions (icon, content, onClick) {
  return [
    { key: 'no', content: 'Cancel', basic: true },
    { key: 'yes', color: 'red', icon, content, onClick }
  ]
}
