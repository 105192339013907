import React, { useEffect } from 'react'
import { Menu, Icon } from '@waylay/react-components'
import { NavLink, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { connect } from 'react-redux'

const StyledMenu = styled('div')`
  margin: 1em 0 1em 1.5em;
  min-width: 145px;
`

const mapStateToProps = () => ({})
const mapDispatchToProps = () => ({})

const Sidenav = (props) => {
  const location = useLocation()

  useEffect(() => {
    React.Children.forEach(props.children, child => {
      if (React.isValidElement(child) && child.type === Menu.Item) {
        return React.cloneElement(child, { active: false })
      }
    })
  }, [location])

  return (
    <StyledMenu>
      <Menu>
        <Menu.Header>Administration</Menu.Header>
        <Menu.List>
          <Menu.Item
            as={NavLink}
            to='/users'
            active={isMatch('/users')}
          >
            <Icon name='supervisor_account' /> Users
          </Menu.Item>
          <Menu.Item
            as={NavLink}
            to='/serviceUsers'
            active={isMatch('/serviceUsers')}
          >
            <Icon name='manage_accounts' /> Service Users
          </Menu.Item>
          <Menu.Item as={NavLink} to='/groups' active={isMatch('/groups')}>
            <Icon name='recent_actors' /> Groups
          </Menu.Item>
          <Menu.Item as={NavLink} to='/tenants' active={isMatch('/tenants')}>
            <Icon name='business' /> Tenants
          </Menu.Item>
          <Menu.Item as={NavLink} to='/apps' active={isMatch('/apps')}>
            <Icon name='apps' /> Applications
          </Menu.Item>
          <Menu.Item as={NavLink} to='/roles' active={isMatch('/roles')}>
            <Icon name='assignment_ind' /> Roles
          </Menu.Item>
          <Menu.Item
            as={NavLink}
            to='/permissions'
            active={isMatch('/permissions')}
          >
            <Icon name='tune' /> Permissions
          </Menu.Item>
          <Menu.Item
            as={NavLink}
            to='/companies'
            active={isMatch('/companies')}
          >
            <Icon name='apartment' /> Companies
          </Menu.Item>
          <Menu.Item
            as={NavLink}
            to='/licenses'
            active={isMatch('/licenses')}
          >
            <Icon name='shield' /> License
          </Menu.Item>
        </Menu.List>
        <Menu.Header>Utilities</Menu.Header>
        <Menu.List>
          <Menu.Item as={NavLink} to='/tokens' active={isMatch('/tokens')}>
            <Icon name='vpn_key' /> Token Generator
          </Menu.Item>
        </Menu.List>
      </Menu>
    </StyledMenu>
  )
}

function isMatch (str) {
  const parts = window.location.pathname.split('/')
  return '/' + parts[1] === str ? 1 : 0
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav)
