const WAYLAY_ACCOUNTS_PUBLIC_URL =
  process.env.WAYLAY_ACCOUNTS_PUBLIC_URL ||
  process.env.REACT_APP_AUTH_URL ||
  window.REACT_APP_AUTH_URL

const WAYLAY_DOMAIN =
  process.env.WAYLAY_DOMAIN ||
  process.env.REACT_APP_WAYLAY_DOMAIN ||
  window.REACT_APP_WAYLAY_DOMAIN

const WAYLAY_IDENTITY_PUBLIC_URL =
  process.env.WAYLAY_IDENTITY_PUBLIC_URL ||
  process.env.REACT_APP_LOGIN_URL ||
  window.REACT_APP_LOGIN_URL

module.exports = Object.freeze({
  WAYLAY_ACCOUNTS_PUBLIC_URL,
  WAYLAY_DOMAIN,
  WAYLAY_IDENTITY_PUBLIC_URL
})
