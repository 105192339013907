import React, { Component } from 'react'
import { Button, Dropdown, Icon, Popup } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import StatusLabel from '../statusLabel'
import ConfirmationModal from '../confirmationModal'
import Gravatar from 'react-gravatar'
import { Table } from '@waylay/react-components'
import { sortBy } from 'lodash'
import TableHeaderCellBold from '../../common/tableHeaderCellBold'
import { withRouter } from '../../common/withRouter'

class CustomTable extends Component {
  constructor (props) {
    super(props)
    this.state = { users: props.users || [] }
  }

  UNSAFE_componentWillReceiveProps ({ users }) {
    this.setState({ users })
  }

  render () {
    const { users } = this.state
    if (!users) return

    const { modal, resetPassword } = this.props
    const sortedUsers = sortBy(users, 'firstname')

    const isServiceUsersView =
      this.props.location.pathname.includes('serviceUsers')
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <TableHeaderCellBold>Name</TableHeaderCellBold>
            {isServiceUsersView
              ? (
                <TableHeaderCellBold>Description</TableHeaderCellBold>
                )
              : (
                <>
                  <TableHeaderCellBold>Email</TableHeaderCellBold>
                  <TableHeaderCellBold>Timezone</TableHeaderCellBold>
                </>
                )}
            <TableHeaderCellBold>Created</TableHeaderCellBold>
            <TableHeaderCellBold>Status</TableHeaderCellBold>
            <TableHeaderCellBold>Actions</TableHeaderCellBold>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedUsers.map((user) =>
            renderUser(user, modal, resetPassword, isServiceUsersView)
          )}
        </Table.Body>
      </Table>
    )
  }
}

function renderUser (user, modal, resetPassword, isServiceUsersView) {
  const { id, fullname, email, status, timezone, description } = user

  const detailLink = (content) => (
    <Link to={isServiceUsersView ? `/serviceUsers/${id}` : `/users/${id}`}>
      {content}
    </Link>
  )

  return (
    <Table.Row key={id}>
      <Table.Cell>
        {detailLink(
          <div>
            <Gravatar
              email={isServiceUsersView ? description : email}
              className='ui mini inline image rounded'
              size={35}
              style={{ marginRight: 10 }}
            />
            {fullname}
          </div>
        )}
      </Table.Cell>
      {isServiceUsersView
        ? (
          <Table.Cell style={{ width: '45%', wordBreak: 'break-all' }}>
            {description ?? '-'}
          </Table.Cell>
          )
        : (
          <>
            <Table.Cell>{detailLink(email)}</Table.Cell>
            <Table.Cell>{timezone || '-'}</Table.Cell>
          </>
          )}

      <Table.Cell>
        <Popup
          basic
          inverted
          position='top center'
          trigger={<span>{moment(user.created_at).fromNow()}</span>}
          content={moment(user.created_at).format('LLL')}
        />
      </Table.Cell>
      <Table.Cell style={{ width: 130 }}>
        <StatusLabel status={status} />
      </Table.Cell>
      <Table.Cell style={{ width: 140 }}>
        <Button.Group basic size='tiny'>
          <Link
            className='ui icon button'
            to={
              !isServiceUsersView
                ? `/users/${user.id}/edit`
                : `/serviceUsers/${user.id}/edit`
            }
          >
            <Icon name='pencil' /> Edit
          </Link>
          <Dropdown button icon='ellipsis horizontal'>
            <Dropdown.Menu>
              {!isServiceUsersView && (
                <Dropdown.Item onClick={(e) => resetPassword(user.email)}>
                  <Icon name='mail outline' /> Reset Password
                </Dropdown.Item>
              )}
              {modal && (
                <ConfirmationModal
                  icon={modal.icon}
                  action={modal.action}
                  trigger={
                    <Dropdown.Item style={{ color: 'red' }}>
                      <Icon name={modal.icon} /> {modal.action}
                    </Dropdown.Item>
                  }
                  header={modal.header}
                  subject={fullname || email || id}
                  onConfirm={modal.onConfirm(id)}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  )
}

export default withRouter(CustomTable)
