import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Navigate, Route, Routes } from 'react-router'
import { Menu } from 'semantic-ui-react'
import { get, reject } from 'lodash'

import DetailView from '../../components/applications/detail'
import Breadcrumb from '../../components/breadcrumb'
import TenantsList from '../../components/tenants/list'
import AddTenant from '../../components/users/addTenant'
import { withRouter } from '../../common/withRouter'
import { errToast } from '../../lib/toast'
import { toast } from 'react-toastify'
import { applications, tenants } from '../../actions'

const { fetchApplication, addTenantToApplication, removeTenantFromApplication, deleteApplication } = applications
const { fetchTenants } = tenants

class Detail extends Component {
  constructor (props) {
    super(props)
    this.state = { application: undefined, tenants: [] }
  }

  componentDidMount () {
    const applicationId = get(this.props, ['match', 'params', 'id'])

    fetchApplication(applicationId)
      .then(({ application }) => this.setState({ application }))
      .catch(errToast)

    fetchTenants()
      .then(({ tenants }) => this.setState({ tenants }))
      .catch(errToast)
  }

  addTenantToApplication = (id) => {
    const applicationId = get(this.state, ['application', 'id'])

    addTenantToApplication(applicationId, id)
      .then(() => {
        const { application, tenants } = this.state

        const newTenant = tenants.find(t => t.id === id)
        const newTenants = [...application.tenants, newTenant]
        const newApplication = { ...application, tenants: newTenants }

        this.setState({ application: newApplication })
      })
      .catch(errToast)
  }

  removeTenantFromApplication = (tenantId) => {
    const applicationId = get(this.state, ['application', 'id'])

    removeTenantFromApplication(applicationId, tenantId)
      .then(() => {
        const { application } = this.state

        const newTenants = reject(application.tenants, { id: tenantId })
        const newApplication = { ...application, tenants: newTenants }

        this.setState({ application: newApplication })
      })
      .catch(errToast)
  }

  deleteApplication = (id) => {
    deleteApplication(id)
      .then(() => {
        toast.warn('Application successfully deleted')
        this.props.history('/apps', { replace: false })
      })
      .catch(errToast)
  }

  getModal () {
    return {
      icon: 'unlinkify',
      action: 'Unlink',
      header: 'Unlink tenant',
      onConfirm: (id) => () => this.removeTenantFromApplication(id)
    }
  }

  render () {
    const { application, tenants } = this.state
    const modal = this.getModal()

    if (!application) return null
    return (
      <div>
        <Menu secondary>
          <Breadcrumb titles={['Applications', application.name]} />
        </Menu>
        <div style={{ display: 'flex' }}>
          <div>
            <Menu pointing secondary vertical fluid>
              <NavLink className='item' to={`/apps/${application.id}/details`}>
                Details
              </NavLink>
              <NavLink className='item' to={`/apps/${application.id}/tenants`}>
                Tenants
              </NavLink>
            </Menu>
          </div>
          <div style={{ flex: 1, margin: '0 15px' }}>
            <div>
              <Routes>
                <Route
                  path='/details' element={(
                    <DetailView
                      app={application}
                      handleDelete={this.deleteApplication}
                    />
                  )}
                />
                <Route
                  path='/tenants' element={(
                    <div>
                      <AddTenant tenants={application.tenants} allTenants={tenants} addTenant={this.addTenantToApplication} />
                      <TenantsList collapsing tenants={application.tenants} modal={modal} />
                    </div>
                  )}
                />
                <Route path='/' element={<Navigate to={`/apps/${application.id}/details`} />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Detail)
