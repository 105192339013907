import React, { Component } from 'react'
import {
  Button,
  Form,
  Header,
  Icon,
  Segment,
  TextArea
} from 'semantic-ui-react'
import { omit } from 'lodash'
import TenantDropdown from '../../containers/tenantDropdown'
import TimezonePicker from '../timezone'
import Breadcrumb from '../breadcrumb'
import { withRouter } from '../../common/withRouter'

const validationMessages = {
  minimum: 'Minimum 8 characters',
  uppercase: '1 uppercase letter',
  lowercase: '1 lowercase letter',
  specialCharacter: '1 special character'
}
class UserForm extends Component {
  constructor (props) {
    super(props)

    this.state = {
      firstname: null,
      lastname: null,
      email: null,
      password: null,
      description: '',
      login_enabled: !this.props.location.pathname.includes('serviceUsers'),
      skipSendingInvitationEmail: false,
      invalid_password: null,
      show_password_validations: false,
      password_validations: {
        minimum: false,
        uppercase: false,
        lowercase: false,
        specialCharacter: false
      }
    }
    this.handleChange = this.handleChange.bind(this)
    this.passwordValidations = this.passwordValidations.bind(this)
  }

  passwordValidations (password) {
    const validations = { ...this.state.password_validations }
    validations.minimum = password.length >= 8
    validations.uppercase = /[A-Z]/.test(password)
    validations.lowercase = /[a-z]/.test(password)
    validations.specialCharacter = /[/\-+_!@#$%^&*., ?]/.test(password)
    const isInvalid = Object.values(validations).includes(false)
    this.setState({ invalid_password: isInvalid })
    this.setState({ password_validations: validations })
  }

  handleChange (event) {
    const { name, value } = event.target
    if (name === 'password') this.passwordValidations(value)
    this.setState({ [name]: value })
  }

  handleDescriptionChange (event) {
    this.setState({ description: event.target.value })
  }

  handleTimezoneChange (timezone) {
    this.setState({ timezone })
  }

  handleTenantChange (tenant) {
    this.setState({ tenant })
  }

  handleSkipSendingInvitationEmailChange (event) {
    const { name, checked } = event.target
    if (name === 'skipSendingInvitationEmail') {
      this.setState({ skipSendingInvitationEmail: checked })
    }
  }

  submit (event) {
    event.preventDefault()
    const payload = omit(this.state, [
      'invalid_password',
      'show_password_validations',
      'password_validations',
      'skipSendingInvitationEmail'
    ])

    if (payload.login_enabled) {
      payload.sendNotificationEmail = !this.state.skipSendingInvitationEmail
    }

    this.props.handleSubmit(payload)
  }

  render () {
    const isServiceUser = this.props.location.pathname.includes('serviceUsers')
    return (
      <div>
        <Breadcrumb />

        <Segment style={{ width: 460, margin: '0 auto' }}>
          <div id='top-bar'>
            <Header>New User</Header>
            <Icon name='add user' />
          </div>
          <Form onSubmit={(e) => this.submit(e)}>
            <Form.Field>
              <label>{isServiceUser ? 'Name' : 'First Name'}</label>
              <input
                name='firstname'
                placeholder={isServiceUser ? 'Name' : 'First Name'}
                value={this.state.firstname}
                onChange={this.handleChange}
              />
            </Form.Field>
            {isServiceUser
              ? (
                <Form.Field>
                  <label>Description</label>
                  <TextArea
                    placeholder='Description'
                    autoHeight
                    value={this.state.description}
                    onChange={(e) => this.handleDescriptionChange(e)}
                  />
                </Form.Field>
                )
              : (
                <>
                  <Form.Field>
                    <label>Last Name</label>
                    <input
                      name='lastname'
                      placeholder='Last Name'
                      value={this.state.lastname}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Email</label>
                    <input
                      name='email'
                      type='email'
                      placeholder='Email'
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Password</label>
                    <input
                      onFocus={() => {
                        const isInvalid = Object.values(
                          this.state.password_validations
                        ).includes(false)
                        this.setState({ show_password_validations: true, invalid_password: isInvalid })
                      }}
                      onBlur={() => {
                        this.setState({
                          invalid_password: null,
                          show_password_validations: false
                        })
                      }}
                      name='password'
                      type='password'
                      style={{
                        borderColor:
                        this.state.invalid_password === null
                          ? null
                          : this.state.invalid_password
                            ? 'red'
                            : '#21BA45'
                      }}
                      placeholder='Password'
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  {this.state.show_password_validations && (
                    <div style={{ marginBottom: '10px', lineHeight: '22px' }}>
                      <div> Password must contain: </div>
                      {Object.entries(this.state.password_validations).map(
                        (validation) => {
                          const name = validation[0]
                          const passes = validation[1]
                          const iconName = passes ? 'check' : 'close'
                          const iconColor = passes ? 'green' : 'red'
                          return (
                            <div key={name}>
                              <Icon
                                name={iconName}
                                color={iconColor}
                                style={{ marginLeft: '10px' }}
                              />
                              {validationMessages[name]}
                            </div>
                          )
                        }
                      )}
                    </div>
                  )}

                  <Form.Field>
                    <label>Timezone</label>
                    <TimezonePicker
                      onTimezone={(tz) => this.handleTimezoneChange(tz)}
                    />
                  </Form.Field>
                </>
                )}
            <Form.Field>
              <label>Tenant</label>
              <TenantDropdown
                onTenantSelected={(e) => this.handleTenantChange(e)}
              />
            </Form.Field>
            {
              !isServiceUser &&
                <Form.Field inline style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ marginRight: '1em' }}>Do not send invitation email</label>
                  <input
                    type='checkbox'
                    name='skipSendingInvitationEmail'
                    checked={this.state.skipSendingInvitationEmail}
                    onChange={(e) => this.handleSkipSendingInvitationEmailChange(e)}
                  />
                </Form.Field>
            }
            <Button
              type='submit'
              primary
              disabled={
                isServiceUser
                  ? false
                  : !(
                      this.state.password_validations.minimum &&
                  this.state.password_validations.uppercase &&
                  this.state.password_validations.lowercase &&
                  this.state.password_validations.specialCharacter
                    )
              }
            >
              Submit
            </Button>
          </Form>
        </Segment>
      </div>
    )
  }
}

export default withRouter(UserForm)
