/* eslint-disable camelcase */
import React, { Component } from 'react'
import { withRouter } from '../../common/withRouter'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { pick } from 'lodash'

import GroupForm from '../../components/groups/form'
import { errToast } from '../../lib/toast'
import { groups } from '../../actions'

const mapDispatchToProps = (dispatch) => ({
  updateGroup: (id, details) => dispatch(groups.updateGroup(id, details))
})

class GroupFormContainer extends Component {
  constructor (props) {
    super(props)

    const { name, description, tenant_id } = props
    this.state = { name, description, tenant_id }
  }

  componentDidMount () {
    const { match } = this.props
    const { params } = match

    groups.fetchGroup(params.id)
      .then(({ group }) => {
        this.setState({ ...this.state, ...pick(group, ['name', 'description', 'tenant_id']) })
      })
      .catch(errToast)
  }

  onSubmit = (formState) => {
    const { updateGroup, match, history } = this.props
    const { params } = match

    updateGroup(params.id, formState)
      .then(({ group }) => {
        const { name } = group
        toast.success(`Group updated: ${name}`)
        history('/groups', { replace: false })
      })
      .catch(errToast)
  }

  render () {
    const { name, description, tenant_id } = this.state

    return (
      <GroupForm
        action='Edit Group'
        name={name}
        description={description}
        tenant_id={tenant_id}
        handleSubmit={this.onSubmit}
      />
    )
  }
}

export default withRouter(connect(
  null,
  mapDispatchToProps
)(GroupFormContainer))
