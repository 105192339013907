import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { withRouter } from '../../common/withRouter'
import FilterableTable from '../../components/users/filterableTable'
import { handleErrorWithToast, errToast } from '../../lib/toast'
import { auth, users } from '../../actions'

const { forgotPassword } = auth
const { fetchUsers, fetchUsersByTenant, deleteUser, fetchServiceUsers, clearUsers } = users

const mapStateToProps = (state) => {
  return {
    users: state.users,
    tenants: state.tenants
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: () => dispatch(fetchUsers()),
  fetchServiceUsers: () => dispatch(fetchServiceUsers()),
  deleteUser: (id) => dispatch(deleteUser(id)),
  fetchUsersByTenant: (tenant, isServiceUser) =>
    dispatch(fetchUsersByTenant(tenant, isServiceUser)),
  resetPassword: (email) => dispatch(forgotPassword(email)),
  clearUsers: () => dispatch(clearUsers())
})

const Users = (props) => {
  useEffect(() => {
    props.clearUsers()
    if (props.location.pathname.includes('serviceUsers')) { props.fetchServiceUsers() } else props.fetchUsers()
  }, [props.location.pathname])

  const fetchUsersByTenant = (tenant) => {
    const { fetchUsers, fetchUsersByTenant } = props
    if (!tenant) return handleErrorWithToast(fetchUsers)() // clear tenant selection
    handleErrorWithToast(fetchUsersByTenant)(
      tenant,
      props.location.pathname.includes('serviceUsers')
    )
  }

  const onDelete = (id) => {
    const { deleteUser } = props
    deleteUser(id)
      .then(() => toast.warn('User successfully deleted'))
      .catch(errToast)
  }

  const onResetPassword = (email) => {
    const { resetPassword } = props
    resetPassword(email)
      .then(() => toast.success(`Email sent to ${email}`))
      .catch(errToast)
  }

  const getModal = () => {
    return {
      icon: 'trash',
      action: 'Delete',
      header: 'Delete user',
      onConfirm: (id) => () => onDelete(id)
    }
  }

  const { users } = props
  const modal = getModal()

  return (
    <FilterableTable
      users={users}
      handleTenantSelection={fetchUsersByTenant}
      handleResetPassword={onResetPassword}
      modal={modal}
    />
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users))
