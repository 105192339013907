import React, { useState } from 'react'
import {
  Form,
  Modal,
  ModalContent,
  ModalHeader,
  ModalActions,
  Icon
} from 'semantic-ui-react'
import { errToast, successToast } from '../../lib/toast'
import { Button } from '@waylay/react-components'

export default ({ handleUpload }) => {
  const [licenseKey, setLicenseKey] = useState('')
  const [open, setOpen] = useState(false)

  const handleSubmit = () => {
    if (licenseKey) {
      handleUpload(licenseKey)
        .then(() => {
          successToast('The license key has been successfully uploaded.')
          setOpen(false)
        })
        .catch((err) => errToast(new Error(err.error ?? err.message)))
        .finally(() => setLicenseKey(''))
    } else {
      errToast('No license key has been entered')
    }
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button type='primary'>
          {' '}
          <Icon name='add' />
          Add license
        </Button>
      }
    >
      <ModalHeader>Upload License</ModalHeader>
      <ModalContent>
        <Form>
          <Form.TextArea
            style={{ minHeight: 150, width: '100%' }} // Adjust width and height as needed
            placeholder='Paste your license key here...'
            value={licenseKey}
            onChange={(e, { value }) => setLicenseKey(value)}
          />
        </Form>
      </ModalContent>
      <ModalActions>
        <Button
          style={{
            backgroundColor: 'teal',
            color: 'white',
            borderColor: 'teal'
          }}
          onClick={handleSubmit}
          disabled={!licenseKey}
        >
          <Icon name='upload' />
          Upload
        </Button>
      </ModalActions>
    </Modal>
  )
}
