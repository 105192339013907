import client from '../lib/client'
import { applications as types } from './types'

function fetchApplication (id) {
  return client.application(id)
    .then(application => ({
      type: types.FETCH_APPLICATION,
      application
    }))
}

function fetchApplications () {
  return client.applications()
    .then(applications => ({
      type: types.FETCH_APPLICATIONS,
      applications
    }))
}

function createApplication (application) {
  return client.createApplication(application)
    .then(application => ({
      type: types.CREATED_APPLICATION,
      application
    }))
}

function deleteApplication (id) {
  return client.deleteApplication(id)
    .then(app => ({
      type: types.DELETED_APPLICATION,
      id
    }))
}

function addTenantToApplication (id, tenantId) {
  return client.addTenantToApplication(id, tenantId)
    .then(() => ({
      type: types.ADD_TENANT_APPLICATION,
      id
    }))
}

function removeTenantFromApplication (id, tenantId) {
  return client.removeTenantFromApplication(id, tenantId)
    .then(() => ({
      type: types.DELETED_TENANT_APPLICATION,
      id
    }))
}

export default {
  fetchApplication,
  fetchApplications,

  createApplication,
  deleteApplication,

  addTenantToApplication,
  removeTenantFromApplication
}
