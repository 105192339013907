import React, { Component } from 'react'
import { Header, Icon } from 'semantic-ui-react'
import { Button } from '@waylay/react-components'
import { NavLink } from 'react-router-dom'

import GroupsTable from '../../components/groups/list'
import authClient from '../../lib/client'

class GroupsList extends Component {
  constructor (props) {
    super(props)
    this.state = { groups: [] }
  }

  async componentDidMount () {
    await this.fetchGroups()
  }

  async fetchGroups () {
    const groups = await authClient.groups()
    this.setState({ groups })
  }

  render () {
    const { groups } = this.state
    return (
      <>
        <div id='top-bar'>
          <Header>
            <Icon name='address book' />{groups.length} Groups
          </Header>
          <NavLink to='/groups/new' style={{ marginLeft: 20 }}>
            <Button>
              <Icon name='add' />Add group
            </Button>
          </NavLink>
        </div>
        <GroupsTable groups={groups} onListUpdated={() => this.fetchGroups()} />
      </>
    )
  }
}

export default GroupsList
