import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import FilterableTable from '../../components/applications/filterableTable'
import { handleErrorWithToast, errToast } from '../../lib/toast'
import { applications } from '../../actions'

const mapStateToProps = (state) => ({
  applications: state.applications
})

const mapDispatchToProps = (dispatch) => ({
  fetchApplications: () => dispatch(applications.fetchApplications()),
  onDelete: (id) => dispatch(applications.deleteApplication(id))
})

class Applications extends Component {
  componentDidMount () {
    const { fetchApplications } = this.props
    handleErrorWithToast(fetchApplications)()
  }

  onDelete = (id) => {
    const { onDelete } = this.props

    onDelete(id)
      .then(() => toast.warn('Application succesfully deleted'))
      .catch(errToast)
  }

  getModal () {
    return {
      header: 'Delete application',
      onConfirm: (id) => () => this.onDelete(id)
    }
  }

  render () {
    const { applications } = this.props
    const modal = this.getModal()

    return <FilterableTable applications={applications} modal={modal} />
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Applications)
