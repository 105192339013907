import React, { Component, Fragment } from 'react'
import { withRouter } from '../../common/withRouter'
import { Header, Icon } from 'semantic-ui-react'
import { Button } from '@waylay/react-components'

import UsersDropdown from '../userDropdown'
import groups from '../../actions/groups'
import DetailView from '../../components/groups/detail'
import UsersTable from '../../components/users/table'
import Breadcrumb from '../../components/breadcrumb'
import authClient from '../../lib/client'

class GroupsDetail extends Component {
  constructor (props) {
    super(props)
    this.state = { details: {} }
  }

  componentDidMount () {
    const { match } = this.props
    const { id } = match.params

    this.fetchGroup(id)
  }

  async fetchGroup (id) {
    const details = await authClient.groups(id)
    this.setState({ details })
  }

  addUserToGroup (userId, groupId) {
    return authClient.assignUserToGroup(userId, groupId)
  }

  handleUserChange (userId) {
    this.setState({ selectedUser: userId })
  }

  assignUser () {
    const { selectedUser, details } = this.state

    groups.assignUserToGroup(selectedUser, details.id)
      .then(() => this.fetchGroup(details.id))
  }

  removeUser (userId) {
    const { details } = this.state

    groups.removeUserFromGroup(userId, details.id)
      .then(() => this.fetchGroup(details.id))
  }

  createRemoveModal (id) {
    return {
      header: 'Unassign user',
      action: 'Unassign',
      icon: 'trash',
      onConfirm: (id) => () => this.removeUser(id)
    }
  }

  render () {
    const { details } = this.state
    const { users = [] } = details
    const modal = this.createRemoveModal()

    return (
      <div>
        <div id='top-bar'>
          <Breadcrumb titles={[undefined, details.name]} />
        </div>

        <Header>Details</Header>
        <DetailView group={details} />

        <Header>Users</Header>

        <UsersDropdown fluid onChange={userId => this.handleUserChange(userId)} />
        <Button style={{ marginLeft: 10 }} onClick={() => this.assignUser()}>
          <>
            <Icon name='add' />Add user
          </>
        </Button>

        <UsersTable users={users} modal={modal} />
      </div>
    )
  }
}

export default withRouter(GroupsDetail)
