import { pick } from 'lodash'
import React, { Component } from 'react'
import {
  Button,
  Dropdown,
  Form,
  Header,
  Icon,
  Input,
  Segment,
  TextArea
} from 'semantic-ui-react'
import TimezonePicker from '../timezone'
import Breadcrumb from '../breadcrumb'
import { withRouter } from '../../common/withRouter'

class UserForm extends Component {
  constructor (props) {
    super(props)

    // only copy properties we can actually edit
    const updatable = [
      'email',
      'firstname',
      'lastname',
      'timezone',
      'status',
      'description'
    ]

    // make a copy of the user prop
    this.state = Object.assign({}, pick(props.user, updatable))
  }

  handleChange (event) {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  handleStatusChange (e, { value }) {
    this.setState({ status: value })
  }

  handleDescriptionChange (event) {
    this.setState({ description: event.target.value })
  }

  handleTimezone (timezone) {
    this.setState({ timezone })
  }

  submit (event) {
    event.preventDefault()
    this.props.updateUser(this.props.user.id, this.state)
  }

  render () {
    const isServiceUser = this.props.location.pathname.includes('serviceUsers')
    const { email, firstname, lastname, timezone, status, description } =
      this.state
    const fullNameOrEmail =
      firstname || lastname ? `${firstname || ''} ${lastname || ''}` : email
    const statusOptions = isServiceUser
      ? [
          { key: 'enabled', text: 'Enabled', value: 'enabled' },
          { key: 'disabled', text: 'Disabled', value: 'disabled' }
        ]
      : [
          { key: 'enabled', text: 'Enabled', value: 'enabled' },
          {
            key: 'unverified',
            text: 'Unverified',
            value: 'unverified'
          },
          { key: 'disabled', text: 'Disabled', value: 'disabled' }
        ]
    return (
      <div>
        <Breadcrumb titles={['Users', fullNameOrEmail, 'Details']} />
        <Segment style={{ width: 460, margin: '0 auto', marginTop: 20 }}>
          <div id='top-bar'>
            <Header>Update User</Header>
            <Icon name='pencil' />
          </div>
          <Form onSubmit={(e) => this.submit(e)}>
            <Form.Field>
              <label>{isServiceUser ? 'Name' : 'First Name'}</label>
              <Input
                name='firstname'
                placeholder={isServiceUser ? 'Name' : 'First Name'}
                value={firstname}
                onChange={(e) => this.handleChange(e)}
              />
            </Form.Field>
            {!isServiceUser && (
              <>
                <Form.Field>
                  <label>Last Name</label>
                  <Input
                    name='lastname'
                    placeholder='Last Name'
                    value={lastname}
                    onChange={(e) => this.handleChange(e)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Email</label>
                  <Input
                    name='email'
                    placeholder='Email'
                    value={email}
                    onChange={(e) => this.handleChange(e)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Timezone</label>
                  <TimezonePicker
                    defaultValue={timezone}
                    onTimezone={(tz) => this.handleTimezone(tz)}
                  />
                </Form.Field>
              </>
            )}
            {isServiceUser && (
              <>
                <Form.Field>
                  <label>Description</label>
                  <TextArea
                    placeholder='Description'
                    autoHeight
                    value={description}
                    onChange={(e) => this.handleDescriptionChange(e)}
                  />
                </Form.Field>
              </>
            )}
            <Form.Field>
              <label>Status</label>
              <Dropdown
                fluid
                selection
                defaultValue={status}
                name='status'
                onChange={(e, data) => this.handleStatusChange(e, data)}
                options={statusOptions}
              />
            </Form.Field>
            <Button primary type='submit'>
              Save
            </Button>
          </Form>
        </Segment>
      </div>
    )
  }
}

export default withRouter(UserForm)
