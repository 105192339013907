import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Header, Icon } from 'semantic-ui-react'
import { without } from 'lodash'
import { Button } from '@waylay/react-components'

import Table from '../../components/permissions/table'
import { errToast } from '../../lib/toast'
import { permissions } from '../../actions'

const { fetchPermissionsConcepts, removePermissionConcept } = permissions

class Permissions extends Component {
  constructor (props) {
    super(props)
    this.state = { concepts: [] }
  }

  componentDidMount () {
    fetchPermissionsConcepts()
      .then(({ concepts }) => this.setState({ concepts }))
      .catch(errToast)
  }

  delete (name) {
    removePermissionConcept(name)
      .then(() => {
        this.setState(prevState => {
          const { concepts } = prevState
          const newConcepts = without(concepts, name)
          return { concepts: newConcepts }
        }, () => toast.success('Permission successfully deleted'))
      })
      .catch(errToast)
  }

  render () {
    return (
      <div>
        <div id='top-bar'>
          <Header>
            <Icon name='key' />{this.state.concepts.length} Permissions
          </Header>
          <NavLink to='/permissions/new' style={{ marginLeft: 20 }}>
            <Button>
              <Icon name='add' />Add concept
            </Button>
          </NavLink>
        </div>
        <Table concepts={this.state.concepts} delete={this.delete.bind(this)} />
      </div>
    )
  }
}

export default Permissions
