import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Header, Icon } from 'semantic-ui-react'
import { Button } from '@waylay/react-components'

import Search from '../search'
import Table from './table'

class FilterableTable extends Component {
  constructor (props) {
    super(props)
    this.state = { filter: '' }
  }

  updateFilter (filter) {
    this.setState({ filter })
  }

  render () {
    const { applications, modal } = this.props
    const { filter } = this.state

    return (
      <div>
        <div id='top-bar'>
          <Header><Icon name='folder outline' />{applications.length} Applications</Header>
          <Search onValue={value => this.updateFilter(value)} />
          <NavLink to='/apps/new' style={{ marginLeft: 20 }}>
            <Button color='green'>
              <Icon name='add' />Add application
            </Button>
          </NavLink>
        </div>
        <Table
          applications={applications}
          filter={filter}
          modal={modal}
        />
      </div>
    )
  }
}

export default FilterableTable
