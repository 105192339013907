import React, { Component } from 'react'
import { get } from 'lodash'

import UserTenants from '../../components/users/tenants'
import { errToast } from '../../lib/toast'
import { tenants, users } from '../../actions'

const { fetchTenants, addUserToTenant, removeUserFromTenant } = tenants
const { fetchUser } = users

class Tenants extends Component {
  constructor (props) {
    super(props)
    this.state = { user: undefined, tenants: undefined }
  }

  componentDidMount () {
    const { user } = this.props

    fetchUser(user)
      .then(({ user }) => this.setState({ user }))
      .catch(errToast)

    fetchTenants()
      .then(({ tenants }) => this.setState({ tenants }))
      .catch(errToast)
  }

  onAddUserToTenant = (tenantId) => {
    const userId = get(this.state, ['user', 'id'])

    addUserToTenant(userId, tenantId)
      .then(() => {
        const { user, tenants } = this.state

        const newTenant = tenants.find(t => t.id === tenantId)
        const newUser = { ...user, tenants: [...user.tenants, newTenant] }
        this.setState({ user: newUser })
      })
      .catch(errToast)
  }

  onRemoveUserFromTenant = (tenantId) => {
    const userId = get(this.state, ['user', 'id'])

    removeUserFromTenant(userId, tenantId)
      .then(() => {
        const { user } = this.state

        const tenants = user.tenants.filter(t => t.id !== tenantId)
        const newUser = { ...user, tenants }
        this.setState({ user: newUser })
      })
      .catch(errToast)
  }

  getModal () {
    return {
      icon: 'unlinkify',
      action: 'Unlink',
      header: 'Unlink tenant',
      onConfirm: (id) => () => this.onRemoveUserFromTenant(id)
    }
  }

  render () {
    const { user = {}, tenants } = this.state
    const { tenants: userTenants } = user
    const modal = this.getModal()

    if (!user) return null
    return (
      <UserTenants
        tenants={tenants}
        userTenants={userTenants}
        handleAddUserToTenant={this.onAddUserToTenant}
        handleRemoveUserFromTenant={this.onRemoveUserFromTenant}
        modal={modal}
      />
    )
  }
}

export default Tenants
