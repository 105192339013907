/** @jsxImportSource @emotion/react */
import React from 'react'
import SideNav from './sidenav/index'
import TopNav from './topnav'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

const flexLayout = css`
  display: flex;
  height: 100vh;
  width: 100%;

  flex-direction: column;
`

const MainWrapper = styled('div')`
  display: flex;
  flex: 1;
`

const Main = styled('div')`
  flex: 1 1 auto;
  overflow: auto;

  padding: 10px 25px;
`

const ESideNav = styled(SideNav)`
  margin: 25px 0 0 25px;
  min-width: 140px;
`

const Layout = ({ children }) => (
  <div css={flexLayout}>
    <TopNav />
    <MainWrapper>
      <ESideNav />
      <Main>
        {children}
      </Main>
    </MainWrapper>
  </div>
)

export default Layout
