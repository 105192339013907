import React from 'react'
import { Header, List, Label } from 'semantic-ui-react'
import { chain } from 'lodash'

export default ({ permissions, itemIcon, onItemClick, color }) => (
  chain(permissions)
    .sortBy(['name', 'scope', 'operation'])
    .groupBy('name')
    .map((permissions, name) => (
      <div key={name}>
        <Header dividing content={name} />
        <List selection verticalAlign='middle'>
          {permissions.map(p => renderPermissionItem(p, itemIcon, onItemClick, color))}
        </List>
      </div>
    ))
    .value()
)

function renderPermissionItem (permission, icon, onClick, color) {
  const { id, scope, name, operation } = permission
  return (
    <List.Item key={id}>
      <List.Content>
        <Label color={color} onClick={e => onClick(id)}>
          {stringify(name, scope, operation)}
        </Label>
      </List.Content>
    </List.Item>
  )
}

function stringify (concept, scope, operation) {
  return [concept, scope, operation]
    .filter(e => Boolean(e))
    .join(':')
}
