import React from 'react'
import { Dropdown } from 'semantic-ui-react'

export default ({ permissions, multiple, onPermissionsSelected }) => {
  const options = permissionsToOptions(permissions)

  return (
    <Dropdown
      search selection
      placeholder='Permissions'
      options={options}
      multiple={multiple}
      onChange={(e, data) => onPermissionsSelected(data.value)}
    />
  )
}

function permissionsToOptions (permissions) {
  const ddPermissions = permissions.map(({ id, name, scope, operation }) => {
    const permString = [name, scope, operation].join(':')
    return { key: id, text: permString, value: permString }
  })

  // add clear selection item
  ddPermissions.unshift({ key: null, text: 'No selection', value: '' })
  return ddPermissions
}
