import client from '../lib/client'
import { auth as types } from './types'
import { jwtDecode } from 'jwt-decode'

const { localStorage } = window

function login (token) {
  assertAdminToken(token)

  setToken(token)
  return {
    type: types.LOGIN_USER,
    token
  }
}

function credentialsLogin (username, password) {
  return client.login(username, password)
    .then(({ token }) => {
      assertAdminToken(token)

      setToken(token)
      return {
        type: types.LOGIN_USER,
        token
      }
    })
}

function ssoLogin (token) {
  assertAdminToken(token)

  setToken(token)
  return {
    type: types.LOGIN_USER,
    token
  }
}

function assertAdminToken (token) {
  const { permissions = [] } = jwtDecode(token)
  const isAdmin = permissions.includes('sudo')

  if (!isAdmin) throw new Error('Insufficient permissions')
}

function setToken (token) {
  client.setToken(token)
  localStorage.setItem('token', token)
  return {
    type: types.SET_TOKEN,
    token
  }
}

function logout () {
  localStorage.removeItem('token')
  client.setToken(null)
  return { type: types.LOGOUT_USER }
}

function forgotPassword (email) {
  return client.forgotPassword(email)
    .then(token => ({
      type: types.FORGOT_PASSWORD,
      user: email
    }))
}

function resetPassword (token, newPassword) {
  return client.resetPassword(token, newPassword)
    .then(res => ({
      type: types.PASSWORD_RESET
    }))
}

function checkToken (token) {
  return client.checkToken(token)
    .then(res => ({
      type: types.TOKEN_VALID
    }))
}

function fetchSSOProviders () {
  return client.ssoConfig()
    .then(providers => ({
      type: types.FETCH_SSO_PROVIDERS,
      providers
    }))
}

export default {
  credentialsLogin,
  login,
  ssoLogin,
  logout,

  forgotPassword,
  resetPassword,
  checkToken,
  setToken,

  fetchSSOProviders
}
