import React, { Component } from 'react'
import { connect } from 'react-redux'

import ApplicationsDropdown from '../components/applicationsDropdown'
import { handleErrorWithToast } from '../lib/toast'
import { applications } from '../actions'

const mapStateToProps = (state) => ({
  applications: state.applications
})

const mapDispatchToProps = (dispatch) => ({
  fetchApplications: () => dispatch(applications.fetchApplications())
})

class ApplicationsDropdownContainer extends Component {
  componentDidMount () {
    const { fetchApplications } = this.props
    handleErrorWithToast(fetchApplications)()
  }

  render () {
    return <ApplicationsDropdown {...this.props} />
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationsDropdownContainer)
