/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { Button, FilterableInput, Icon, Input, Segment } from '@waylay/react-components'
import { css } from '@emotion/react'

const boost = css`
  width: 300px;
`
class SearchBar extends Component {
  onChange (input) {
    this.props.onValue(input)
  }

  render () {
    return (
      <form>
        <FilterableInput
          name='search'
          initialValue=''
          value=''
          popup={({ resetFilter }) => (
            <Segment.Group>
              <Segment.Footer>
                <Button onClick={() => { this.onChange(); resetFilter() }}>
                  <Icon name='clear' /> Clear search
                </Button>
              </Segment.Footer>
            </Segment.Group>
          )}
          input={props => <Input {...props} css={boost} icon={{ name: 'search', circular: true }} placeholder='Search…' />}
          onChange={input => this.onChange(input)}
        />
      </form>
    )
  }
}

export default SearchBar
