import React, { useEffect, useState } from 'react'
import Table from './table'
import TopBar from './top'
import { withRouter } from '../../common/withRouter'

const FilterableTable = (props) => {
  const { users, modal, handleTenantSelection, handleResetPassword } = props

  const [filter, setFilter] = useState('')
  const [filteredUsers, setFilteredUsers] = useState(users || [])

  const filterAction = (filter) => {
    setFilter(filter)
  }

  useEffect(() => {
    setFilter('')
    setFilteredUsers([])
  }, [props.location.pathname])

  useEffect(() => {
    const newUsers = users.filter((user) => {
      const regex = new RegExp(filter, 'i')
      return (
        user?.fullname?.match(regex) ||
        user?.email?.match(regex) ||
        user?.id?.match(regex)
      )
    })
    setFilteredUsers(newUsers)
  }, [filter, users])

  return (
    <div>
      <TopBar
        filter={filter}
        users={filteredUsers}
        onFilter={(value) => filterAction(value)}
        onTenantSelection={handleTenantSelection}
      />
      <Table
        users={filteredUsers}
        filter={filter}
        resetPassword={handleResetPassword}
        modal={modal}
      />
    </div>
  )
}

export default withRouter(FilterableTable)
