/* eslint-disable camelcase */
import moment from 'moment'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Popup, Button } from 'semantic-ui-react'
import { Table } from '@waylay/react-components'
import { sortBy } from 'lodash'

import ClipboardText from '../copyToClipboard'
import ConfirmationModal from '../confirmationModal'
import TableHeaderCellBold from '../../common/tableHeaderCellBold'

class CustomTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      applications: this.props.applications || []
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const { applications } = nextProps
    this.setState({ applications })
  }

  getApplications () {
    const { applications = [] } = this.state
    const filteredApps = applications.filter(app => this.matchNameOrDescription(app))
    return sortBy(filteredApps, 'name')
  }

  matchNameOrDescription (app) {
    const { filter } = this.props
    const regex = new RegExp(filter, 'i')

    const matchName = app.name.match(regex)
    const matchDescription = (app.description || '').match(regex)
    return matchName || matchDescription
  }

  render () {
    const applications = this.getApplications()
    const { modal } = this.props

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <TableHeaderCellBold>
              ID
            </TableHeaderCellBold>
            <TableHeaderCellBold>
              Name
            </TableHeaderCellBold>
            <TableHeaderCellBold>
              Description
            </TableHeaderCellBold>
            <TableHeaderCellBold>Secret</TableHeaderCellBold>
            <TableHeaderCellBold>
              Created
            </TableHeaderCellBold>
            <TableHeaderCellBold style={{ width: 120 }}>Actions</TableHeaderCellBold>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {applications.map(app => renderApp(app, modal))}
        </Table.Body>
      </Table>
    )
  }
}

function renderApp ({ id, name, description, secret, created_at }, modal) {
  return (
    <Table.Row key={id}>
      <Table.Cell>
        <Link to={`/apps/${id}/details`}>{id}</Link>
      </Table.Cell>
      <Table.Cell>
        <Link to={`/apps/${id}/details`}>{name}</Link>
      </Table.Cell>
      <Table.Cell>{description}</Table.Cell>
      <Table.Cell>
        <ClipboardText value={secret} />
      </Table.Cell>
      <Table.Cell>
        <Popup
          basic inverted position='top center'
          trigger={<span>{moment(created_at).fromNow()}</span>}
          content={moment(created_at).format('LLL')}
        />
      </Table.Cell>
      <Table.Cell>
        <Button.Group basic size='tiny'>
          <ConfirmationModal
            icon={modal.icon}
            action={modal.action}
            header={modal.header}
            subject={name}
            onConfirm={modal.onConfirm(id)}
          />
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  )
}

export default CustomTable
