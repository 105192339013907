export default {
  FETCH_APPLICATION: 'FETCH_APPLICATION',
  FETCH_APPLICATIONS: 'FETCH_APPLICATIONS',

  CREATED_APPLICATION: 'CREATED_APPLICATION',
  DELETED_APPLICATION: 'DELETED_APPLICATION',

  ADD_TENANT_APPLICATION: 'ADD_TENANT_APPLICATION',
  DELETED_TENANT_APPLICATION: 'DELETED_TENANT_APPLICATION'
}
