import React, { Component } from 'react'
import { Button, Form, Header, Icon, Segment } from 'semantic-ui-react'
import Breadcrumb from '../breadcrumb'

class PermissionForm extends Component {
  constructor (props) {
    super(props)
    this.state = { name: '' }
  }

  handleNameChange (event) {
    this.setState({ name: event.target.value })
  }

  handleDomainChange (event) {
    this.setState({ description: event.target.value })
  }

  submit (event) {
    event.preventDefault()
    this.props.handleSubmit(this.state)
  }

  render () {
    return (
      <div>
        <Breadcrumb />
        <Segment style={{ width: 460, margin: '0 auto' }}>
          <div id='top-bar'>
            <Header>New Concept</Header>
            <Icon name='add' />
          </div>
          <Form onSubmit={e => this.submit(e)}>
            <Form.Field>
              <label>Name</label>
              <input placeholder='Name' value={this.state.name} onChange={e => this.handleNameChange(e)} />
            </Form.Field>
            <Button type='submit'>Submit</Button>
          </Form>
        </Segment>
      </div>
    )
  }
}

export default PermissionForm
