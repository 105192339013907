import React, { Component } from 'react'
import { connect } from 'react-redux'

import UsersDropdown from '../components/usersDropdown'
import { handleErrorWithToast } from '../lib/toast'
import { users } from '../actions'

const mapStateToProps = (state) => ({
  users: state.users
})
const mapDispatchToProps = (dispatch) => ({
  fetchUsers: () => dispatch(users.fetchUsers())
})

class UsersDropdownContainer extends Component {
  componentDidMount () {
    const { fetchUsers } = this.props
    handleErrorWithToast(fetchUsers)()
  }

  render () {
    return <UsersDropdown {...this.props} />
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersDropdownContainer)
