export default {
  FETCH_ROLE: 'FETCH_ROLE',
  FETCH_ROLES: 'FETCH_ROLES',
  FETCH_ROLE_USERS: 'FETCH_ROLE_USERS',

  ROLE_CREATED: 'ROLE_CREATED',
  ROLE_REMOVED: 'ROLE_REMOVED',
  ROLE_UPDATED: 'ROLE_UPDATED',

  PERMISSION_ROLE_ADDED: 'PERMISSION_ROLE_ADDED',
  PERMISSION_ROLE_REMOVED: 'PERMISSION_ROLE_REMOVED'
}
