import React, { Component } from 'react'
import { isArray, omit } from 'lodash'

import TenantsDropdown from '../components/tenantsDropdown'
import { errToast } from '../lib/toast'
import { tenants } from '../actions'

const { fetchTenants } = tenants

class TenantsDropdownContainer extends Component {
  constructor (props) {
    super(props)

    const { tenants = [] } = this.props
    this.state = { tenants }
  }

  componentDidMount () {
    const { tenants } = this.props
    this.handleTenantFetching(tenants)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.tenants !== this.props.tenants) {
      this.handleTenantFetching(this.props.tenants)
    }
  }

  async handleTenantFetching (tenants) {
    // Fetch all tenants if none were passed by props
    if (!isArray(tenants)) {
      fetchTenants()
        .then(({ tenants }) => this.setState({ tenants }))
        .catch(errToast)
    } else {
      this.setState({ tenants })
    }
  }

  render () {
    const { tenants } = this.state
    const props = omit(this.props, 'tenants')
    return <TenantsDropdown tenants={tenants} {...props} />
  }
}

export default TenantsDropdownContainer
