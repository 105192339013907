import React, { Component } from 'react'

import { withRouter } from '../../common/withRouter'
import Breadcrumb from '../../components/breadcrumb'
import LicenseViewer from '../../components/licenses/viewer'

import authClient from '../../lib/client'
import { errToast } from '../../lib/toast'

class LicenseDetail extends Component {
  constructor (props) {
    super(props)
    this.state = { license: {} }
  }

  async componentDidMount () {
    const { match } = this.props
    const { id } = match.params

    try {
      await this.fetchLicense(id)
    } catch (err) {
      errToast({ message: err.error ?? err.message })
    }
  }

  async fetchLicense (id) {
    const license = await authClient.getLicenseById(id)
    this.setState({ license })
  }

  render () {
    const { license } = this.state

    return (
      <div>
        <Breadcrumb titles={['Licenses']} capitalize={false} />
        <LicenseViewer license={license} />
      </div>
    )
  }
}

export default withRouter(LicenseDetail)
