import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { Table } from '@waylay/react-components'
import { Link } from 'react-router-dom'
import TableHeaderCellBold from '../../common/tableHeaderCellBold'
import { tenants } from '../../actions'

const { fetchTenants } = tenants
const DEFAULT_TENANT_ID_TO_NAME_MAPPING_ENTRY = {
  '': 'Global license'
}

export default ({ licenses }) => {
  const [tenantsIdToName, setTenantsIdToName] = useState(DEFAULT_TENANT_ID_TO_NAME_MAPPING_ENTRY)

  useEffect(() => {
    if (!isEmpty(licenses)) {
      fetchTenants()
        .then(({ tenants }) => tenants.reduce((_, tenant) => {
          _[tenant.id] = tenant.name
          return _
        }, {}))
        .then(_ => setTenantsIdToName({ ..._, ...DEFAULT_TENANT_ID_TO_NAME_MAPPING_ENTRY }))
    }
  }, [licenses])

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <TableHeaderCellBold>Tenant</TableHeaderCellBold>
            <TableHeaderCellBold>Description</TableHeaderCellBold>
            <TableHeaderCellBold>Start date</TableHeaderCellBold>
            <TableHeaderCellBold>End date</TableHeaderCellBold>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {licenses.map(license => (
            <Table.Row key={license.id}>
              <Table.Cell>
                {tenantsIdToName[license.tenantId ?? '']}
              </Table.Cell>
              <Table.Cell>
                <Link to={`/licenses/${license.id}`}>
                  {license.description}
                </Link>
              </Table.Cell>
              <Table.Cell>
                {timestamp(license.start)}
              </Table.Cell>
              <Table.Cell>
                {timestamp(license.end)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  )
}

const timestamp = (datetime) => {
  return datetime ? moment(datetime).utc().format('LLL z') : null
}
