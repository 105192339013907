import React from 'react'
import { Header, Grid, Segment } from 'semantic-ui-react'
import { jwtDecode } from 'jwt-decode'
import { Button } from '@waylay/react-components'

import CopyToClipboard from '../copyToClipboard'

const COLORS = {
  header: '#FB015B',
  body: '#D63AFF',
  signature: '#00B9F1'
}

const headerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 15
}

export default ({ jwt }) => {
  if (!jwt) return null

  const [header, body, signature] = jwt.split('.')
  const decodedHeader = jwtDecode(jwt, { header: true })
  const decodedBody = jwtDecode(jwt)

  return (
    <div>
      <div style={headerStyles}>
        <Header style={{ margin: 0 }}>JWT token</Header>
        <CopyToClipboard
          target={<Button>Copy token</Button>}
          value={jwt}
        />
      </div>
      <Grid divided='vertically'>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Segment attached>
              <code style={{ wordWrap: 'break-word', fontSize: '1.2em' }}>
                <span style={{ color: COLORS.header }}>{header}</span>.
                <span style={{ color: COLORS.body }}>{body}</span>.
                <span style={{ color: COLORS.signature }}>{signature}</span>
              </code>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment.Group>
              {/* header */}
              <Header as='h5' attached='top'>Header</Header>
              <Segment attached>
                <pre>
                  <code style={{ color: COLORS.header }}>
                    {JSON.stringify(decodedHeader, null, 2)}
                  </code>
                </pre>
              </Segment>
              {/* body */}
              <Header as='h5' attached>Body</Header>
              <Segment attached>
                <pre>
                  <code style={{ color: COLORS.body }}>
                    {JSON.stringify(decodedBody, null, 2)}
                  </code>
                </pre>
              </Segment>
            </Segment.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}
