import React, { Component } from 'react'
import { withRouter } from '../../common/withRouter'
import { get } from 'lodash'

import NewSettingsForm from '../../components/tenants/newSetting'
import { errToast } from '../../lib/toast'
import { tenants } from '../../actions'

const { createTenantSetting } = tenants

class NewSetting extends Component {
  handleSubmit = (formState) => {
    const tenantId = get(this.props, ['match', 'params', 'id'])

    createTenantSetting(tenantId, formState)
      .then(() => this.props.history(`/tenants/${tenantId}/settings`, { replace: false }))
      .catch(errToast)
  }

  render () {
    const tenantId = get(this.props, ['match', 'params', 'id'])
    return <NewSettingsForm tenantId={tenantId} onSubmit={this.handleSubmit} />
  }
}

export default withRouter(NewSetting)
