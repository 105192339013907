import React, { Component } from 'react'
import { Button, Form, Header, Icon, Input, Segment } from 'semantic-ui-react'
import { get, isEmpty } from 'lodash'

import Breadcrumb from '../breadcrumb'
import { errToast } from '../../lib/toast'

class EditTenantForm extends Component {
  constructor (props) {
    super(props)

    const { name, domain, description, id } = props.tenant
    this.state = { name, domain, description, id }
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    if (isEmpty(this.state.name?.trim())) {
      return errToast({ message: 'Name is required' })
    }

    const tenantId = get(this.props, ['tenant', 'id'])
    this.props.handleSubmit(tenantId, this.state)
  }

  render () {
    const { name, domain } = this.state
    const description = this.state.description ?? ''

    return (
      <div>
        <Breadcrumb titles={[undefined, name]} />

        <Segment style={{ width: 460, margin: '0 auto', marginTop: 20 }}>
          <div id='top-bar'>
            <Header>Update Tenant</Header>
            <Icon name='pencil' />
          </div>
          <Form onSubmit={this.handleSubmit}>
            <Form.Field>
              <label>Name</label>
              <Input name='name' placeholder='Name' value={name} onChange={this.handleChange} />
            </Form.Field>
            <Form.Field>
              <label>Domain</label>
              <Input name='domain' placeholder='Domain' value={domain} onChange={this.handleChange} />
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <Input name='description' placeholder='Description' value={description} onChange={this.handleChange} />
            </Form.Field>
            <Button type='submit'>Submit</Button>
          </Form>
        </Segment>
      </div>
    )
  }
}

export default EditTenantForm
