import React, { Component } from 'react'
import ForgotPasswordScreen from '../../components/password/forgotPassword'

import { auth } from '../../actions'
const { forgotPassword } = auth

class ForgotPassword extends Component {
  constructor (props) {
    super(props)
    this.state = { message: undefined }
  }

  onSubmit = (email) => {
    forgotPassword(email)
      .then((token) => {
        this.setState({
          message: 'You should receive an email to reset your password.',
          success: true,
          error: false
        })
      })
      .catch((err) => this.setState({ error: err.message, success: false }))
  }

  render () {
    return <ForgotPasswordScreen {...this.state} handleSubmit={this.onSubmit} />
  }
}

export default ForgotPassword
