import React from 'react'
import { Button, Icon, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ConfirmationModal from '../confirmationModal'
import CopyToClipboard from '../copyToClipboard'
import { Table } from '@waylay/react-components'
import TableHeaderCellBold from '../../common/tableHeaderCellBold'

const actionColumnStyles = { width: 120 }
const actionCellStyles = { textAlign: 'center' }
const cellStyles = {
  display: 'flex',
  alignItems: 'center'
}
const cellContentStyles = {
  display: 'inline-block',
  maxWidth: '95%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginRight: 5
}

export default ({ settings, tenantId, onDeleteSetting }) => {
  if (!settings) return null

  return (
    <div>
      <div id='top-bar'>
        <Header>Settings</Header>
        <Link className='ui button green' to={`/tenants/${tenantId}/settings/new`}>
          <Icon name='add' />Add setting
        </Link>
      </div>
      <Table fixed>
        <Table.Header>
          <Table.Row>
            <TableHeaderCellBold>Key</TableHeaderCellBold>
            <TableHeaderCellBold>Value</TableHeaderCellBold>
            <TableHeaderCellBold style={actionColumnStyles}>Actions</TableHeaderCellBold>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {settings.map(setting => (
            <Setting key={setting.key} setting={setting} onDelete={onDeleteSetting} />
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

const Setting = ({ setting, onDelete }) => {
  const { key, value } = setting
  return (
    <Table.Row>
      <Table.Cell>
        <div style={cellStyles}>
          <div style={cellContentStyles}>{key}</div>
          <CopyToClipboard target={<Icon color='grey' name='clipboard' />} value={key} />
        </div>
      </Table.Cell>
      <Table.Cell>
        <div style={cellStyles}>
          <div style={cellContentStyles}>{value}</div>
          <CopyToClipboard target={<Icon color='grey' name='clipboard' />} value={value} />
        </div>
      </Table.Cell>
      <Table.Cell style={actionCellStyles}>
        <Button.Group basic size='tiny'>
          {renderModal(setting, onDelete)}
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  )
}

function renderModal ({ key, value }, onDelete) {
  return (
    <ConfirmationModal
      header='Delete setting'
      subject={key}
      onConfirm={() => onDelete(key)}
    />
  )
}
