import React, { Component } from 'react'
import { Button, Form, Header, Segment, TextArea } from 'semantic-ui-react'
import { upperFirst } from 'lodash'

import Breadcrumb from '../breadcrumb'

class FormComponent extends Component {
  constructor (props) {
    super(props)
    this.state = { name: '', description: '' }
  }

  UNSAFE_componentWillReceiveProps (props) {
    this._hydrateState(props)
  }

  _hydrateState (props) {
    const { name = '', description = '' } = props
    this.setState({ name, description })
  }

  handleNameChange (event) {
    this.setState({ name: event?.target?.value })
  }

  handleDescriptionChange (event) {
    this.setState({ description: event?.target?.value })
  }

  submit (event) {
    event.preventDefault()
    this.props.handleSubmit(this.state)
  }

  render () {
    const { action } = this.props
    const { name, description } = this.state

    return (
      <div>
        <Breadcrumb />
        <Segment style={{ width: 460, margin: '0 auto' }}>
          <div id='top-bar'>
            <Header>{upperFirst(action)}</Header>
          </div>
          <Form onSubmit={e => this.submit(e)}>
            <Form.Field>
              <label>Name</label>
              <input placeholder='Name' value={name} onChange={e => this.handleNameChange(e)} />
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <TextArea placeholder='Description' autoheight='true' value={description} onChange={e => this.handleDescriptionChange(e)} />
            </Form.Field>
            <Button type='submit'>Submit</Button>
          </Form>
        </Segment>
      </div>
    )
  }
}

export default FormComponent
