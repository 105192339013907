import { roles as actionTypes } from '../actions/types'
import { reject } from 'lodash'

export default (roles = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_ROLES:
      return [].concat(action.roles)
    case actionTypes.ROLE_CREATED:
      return [].concat(roles.push(action))
    case actionTypes.ROLE_REMOVED:
      return reject(roles, { id: action.id })
    default:
      return roles
  }
}
