import { toast } from 'react-toastify'

/*
 * Notice!
 * The error handler in this fn will only catch errors from the given promise.
 * When appending `then` handlers on the return promise, make sure you properly
 * handle any further errors.
 */
export function handleErrorWithToast (promiseFn) {
  return (...params) => {
    return promiseFn(...params)
      .catch(errToast)
  }
}

export function errToast (err) {
  toast.error(err.message)
}

export function successToast (message, options = { autoClose: 2000 }) {
  toast.success(message, { ...options })
}
