import React, { Component } from 'react'
import { Button, Form, Header, Icon, Segment, TextArea } from 'semantic-ui-react'
import Breadcrumb from '../breadcrumb'

import TenantsDropdown from '../../containers/tenantDropdown'

class ApplicationForm extends Component {
  constructor (props) {
    super(props)
    this.state = { name: '', tenants: [], description: '' }
  }

  handleNameChange (event) {
    this.setState({ name: event.target.value })
  }

  handleTenantSelection (tenants) {
    this.setState({ tenants })
  }

  handleDescriptionChange (event) {
    this.setState({ description: event.target.value })
  }

  submit (event) {
    event.preventDefault()
    this.props.handleSubmit(this.state)
  }

  render () {
    return (
      <div>
        <Breadcrumb titles={['Applications', 'New Application']} />
        <Segment style={{ width: 460, margin: '0 auto' }}>
          <div id='top-bar'>
            <Header>New Application</Header>
            <Icon name='add' />
          </div>
          <Form onSubmit={e => this.submit(e)}>
            <Form.Field>
              <label>Name</label>
              <input placeholder='Name' value={this.state.name} onChange={e => this.handleNameChange(e)} />
            </Form.Field>
            <Form.Field>
              <label>Tenants</label>
              <TenantsDropdown multiple onTenantSelected={e => this.handleTenantSelection(e)} />
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <TextArea placeholder='Description' autoheight='true' value={this.state.description} onChange={e => this.handleDescriptionChange(e)} />
            </Form.Field>
            <Button type='submit'>Submit</Button>
          </Form>
        </Segment>
      </div>
    )
  }
}

export default ApplicationForm
