export default {
  FETCH_TENANT: 'FETCH_TENANT',
  FETCH_TENANTS: 'FETCH_TENANTS',

  CREATED_TENANT: 'CREATED_TENANT',
  UPDATED_TENANT: 'UPDATED_TENANT',
  DELETED_TENANT: 'DELETED_TENANT',

  USER_ADDED_TO_TENANT: 'USER_ADDED_TO_TENANT',
  USER_REMOVED_FROM_TENANT: 'USER_REMOVED_FROM_TENANT',

  CREATED_TENANT_SETTING: 'CREATED_TENANT_SETTING',
  DELETED_TENANT_SETTING: 'DELETED_TENANT_SETTING'
}
