import React from 'react'
import { Button, Card, Table } from 'semantic-ui-react'
import Gravatar from 'react-gravatar'
import { Link } from 'react-router-dom'
import ConfirmationModal from '../confirmationModal'
import { withRouter } from '../../common/withRouter'

const cardStyles = { width: '50%', maxWidth: 500 }

export default withRouter(
  ({
    location,
    user,
    handleEnable,
    handleDisable,
    handleUnlock,
    handleDelete,
    handlePasswordReset
  }) => {
    if (!user) return null

    const {
      id,
      email,
      fullname,
      status,
      is_locked: isLocked,
      description,
      timezone,
      created_at: createdAt,
      updated_at: updatedAt
    } = user
    const statusLabel = getStatusLabel(status, handleEnable, handleDisable)
    const lockedLabel = getLockedLabel(isLocked, handleUnlock)

    const isServiceUser = location.pathname.includes('serviceUsers')

    return (
      <Card style={cardStyles}>
        <Card.Content>
          <Gravatar
            email={email}
            className='ui mini right floated image rounded'
            size={35}
          />
          <Card.Header>
            {fullname} {statusLabel} {lockedLabel}
          </Card.Header>
          <Card.Meta>{isServiceUser ? description ?? '-' : email}</Card.Meta>
          <Card.Meta>{id}</Card.Meta>
          <Card.Description>
            <Table basic='very'>
              <Table.Body>
                {!isServiceUser && (
                  <>
                    <Table.Row>
                      <Table.Cell>Timezone</Table.Cell>
                      <Table.Cell textAlign='right'>
                        {timezone || '-'}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>2FA</Table.Cell>
                      <Table.Cell textAlign='right'>
                        {user.twofa ? 'Enabled' : 'Disabled'}
                      </Table.Cell>
                    </Table.Row>
                  </>
                )}
                <Table.Row>
                  <Table.Cell>Created at</Table.Cell>
                  <Table.Cell textAlign='right'>{createdAt}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Updated at</Table.Cell>
                  <Table.Cell textAlign='right'>{updatedAt}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <div className='ui three buttons'>
            <Link
              className='ui button basic grey'
              to={
                isServiceUser ? `/serviceUsers/${id}/edit` : `/users/${id}/edit`
              }
            >
              Edit
            </Link>
            {!isServiceUser && (
              <Button color='grey' basic onClick={handlePasswordReset}>
                Reset Password
              </Button>
            )}
            {renderDeleteModal(user, handleDelete)}
          </div>
        </Card.Content>
      </Card>
    )
  }
)

function getStatusLabel (status, onEnable, onDisable) {
  const buttonStyle = { marginTop: '5px', marginBottom: '5px' }
  switch (status) {
    case 'enabled':
      return (
        <Button
          basic
          content='Enabled'
          size='tiny'
          color='green'
          style={buttonStyle}
          onClick={onDisable}
        />
      )
    case 'disabled':
      return (
        <Button
          basic
          content='Disabled'
          size='tiny'
          color='red'
          style={buttonStyle}
          onClick={onEnable}
        />
      )
    case 'unverified':
    default:
      return (
        <Button
          basic
          content='Unverified'
          size='tiny'
          style={buttonStyle}
          onClick={onEnable}
        />
      )
  }
}

function getLockedLabel (isLocked, onUnlock) {
  const buttonStyle = { marginTop: '5px', marginBottom: '5px' }
  if (isLocked) {
    return (
      <Button
        basic
        content='Locked'
        size='tiny'
        color='orange'
        style={buttonStyle}
        onClick={onUnlock}
      />
    )
  } else {
    return (
      <Button
        basic
        content='Unlocked'
        size='tiny'
        color='olive'
        style={buttonStyle}
      />
    )
  }
}

function renderDeleteModal ({ fullname, email }, onDelete) {
  return (
    <ConfirmationModal
      header='Delete user'
      subject={fullname || email}
      onConfirm={() => onDelete()}
    />
  )
}
