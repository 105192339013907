/* eslint-disable camelcase */
import React, { Component } from 'react'
import { Button, Form, Header, Segment, TextArea } from 'semantic-ui-react'
import { upperFirst } from 'lodash'

import TenantsDropdown from '../../containers/tenantDropdown'
import Breadcrumb from '../breadcrumb'

class FormComponent extends Component {
  constructor (props) {
    super(props)
    this.state = { name: '', description: '', tenant_id: '' }
  }

  UNSAFE_componentWillReceiveProps (props) {
    this._hydrateState(props)
  }

  _hydrateState (props) {
    const { name = '', description = '', tenant_id = '' } = props
    this.setState({ name, description, tenant_id })
  }

  handleNameChange (event) {
    this.setState({ name: event?.target?.value })
  }

  handleDescriptionChange (event) {
    this.setState({ description: event?.target?.value })
  }

  handleTenantChange (tenantId) {
    if (tenantId) {
      this.setState({ tenant_id: tenantId })
    }
  }

  submit (event) {
    event.preventDefault()
    this.props.handleSubmit(this.state)
  }

  render () {
    const { action } = this.props
    const { name, description, tenant_id } = this.state
    return (
      <div>
        <Breadcrumb />
        <Segment style={{ width: 460, margin: '0 auto' }}>
          <div id='top-bar'>
            <Header>{upperFirst(action)}</Header>
          </div>
          <Form onSubmit={e => this.submit(e)}>
            <Form.Field>
              <label>Name</label>
              <input placeholder='Name' value={name} onChange={e => this.handleNameChange(e)} />
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <TextArea placeholder='Description' autoheight='true' value={description} onChange={e => this.handleDescriptionChange(e)} />
            </Form.Field>
            <Form.Field>
              <label>Tenant</label>
              <TenantsDropdown selectedTenant={tenant_id} onTenantSelected={e => this.handleTenantChange(e)} />
            </Form.Field>
            <Button type='submit'>Submit</Button>
          </Form>
        </Segment>
      </div>
    )
  }
}

export default FormComponent
