import { Table } from '@waylay/react-components'

export default (props) => {
  const newProps = { ...props }
  if (newProps.style) {
    newProps.style = { ...newProps.style, fontWeight: 1000 }
  } else {
    newProps.style = { fontWeight: 1000 }
  }

  return <Table.HeaderCell {...newProps} />
}
