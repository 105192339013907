import React from 'react'
import { Table } from '@waylay/react-components'
import { Button, Popup, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import ConfirmationModal from '../confirmationModal'
import authClient from '../../lib/client'
import { errToast } from '../../lib/toast'
import TableHeaderCellBold from '../../common/tableHeaderCellBold'

import moment from 'moment'

export default ({ companies, onListUpdated }) => (
  <Table>
    <Table.Header>
      <Table.Row>
        <TableHeaderCellBold>ID</TableHeaderCellBold>
        <TableHeaderCellBold>Name</TableHeaderCellBold>
        <TableHeaderCellBold>Description</TableHeaderCellBold>
        <TableHeaderCellBold>Updated At</TableHeaderCellBold>
        <TableHeaderCellBold>Created At</TableHeaderCellBold>
        <TableHeaderCellBold>Actions</TableHeaderCellBold>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {companies.map(company => (
        <Table.Row key={company.id}>
          <Table.Cell>
            <Link to={`/companies/${company.id}`}>
              {company.id}
            </Link>
          </Table.Cell>
          <Table.Cell>
            {company.name}
          </Table.Cell>
          <Table.Cell>
            {company.description}
          </Table.Cell>
          <Table.Cell>
            {timestamp(company.updated_at)}
          </Table.Cell>
          <Table.Cell>
            {timestamp(company.created_at)}
          </Table.Cell>
          <Table.Cell>
            <Button.Group basic size='tiny'>
              <Link className='ui icon button' to={`/companies/${company.id}/edit`}>
                <Icon name='pencil' /> Edit
              </Link>
              <ConfirmationModal
                header={`Remove "${company.name}"`}
                subject={company.name}
                onConfirm={(_) => onDeleteConfirmation(company.id, onListUpdated)}
              />
            </Button.Group>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

const timestamp = (datetime) => {
  return datetime
    ? (
      <Popup
        basic inverted position='top center'
        trigger={
          <span>
            {moment(datetime).fromNow()}
          </span>
      }
        content={
        moment(datetime).format('LLL')
      }
      />
      )
    : null
}

function onDeleteConfirmation (id, onListUpdated) {
  authClient.removeCompany(id)
    .then(() => onListUpdated())
    .catch(errToast)
}
