import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from '../../common/withRouter'
import { toast } from 'react-toastify'

import NewPermissionsForm from '../../components/permissions/new'
import { errToast } from '../../lib/toast'
import { permissions } from '../../actions'

const mapDispatchToProps = (dispatch) => ({
  createPermissionConcept: details => dispatch(permissions.createPermissionConcept(details))
})

class NewPermissionsConceptForm extends Component {
  onSubmit = formState => {
    const name = formState.name.toLowerCase()

    const { createPermissionConcept, history } = this.props
    createPermissionConcept(name)
      .then(() => {
        toast.success(`Permission created: ${name}`)
        history('/permissions', { replace: false })
      })
      .catch(errToast)
  }

  render () {
    return <NewPermissionsForm handleSubmit={this.onSubmit} />
  }
}

export default withRouter(connect(
  null,
  mapDispatchToProps
)(NewPermissionsConceptForm))
