import { auth as actionTypes } from '../actions/types'

const { localStorage } = window

const initialState = {
  loggedIn: Boolean(localStorage.getItem('token'))
}

export default (auth = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER:
      return { ...auth, loggedIn: true }
    case actionTypes.LOGOUT_USER:
      return { ...auth, loggedIn: false }
    default:
      return auth
  }
}
