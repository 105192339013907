/* eslint-disable camelcase */
import React, { Component } from 'react'
import { Header, Table } from 'semantic-ui-react'
import ConfirmationModal from '../confirmationModal'

class Detail extends Component {
  render () {
    const { app, handleDelete } = this.props
    if (!app) return null

    const { name, description, public_key: publicKey, created_at, updated_at } = app

    return (
      <div>
        <div id='top-bar'>
          <Header>Details</Header>
          {renderDeleteLink(app, handleDelete)}
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='2'>{name}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>Description</Table.Cell>
              <Table.Cell>{description}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Public key</Table.Cell>
              <Table.Cell>
                <code><pre>{publicKey}</pre></code>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{new Date(created_at).toString()}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{new Date(updated_at).toString()}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    )
  }
}

function renderDeleteLink ({ id, name }, onDelete) {
  return (
    <ConfirmationModal
      header='Delete application'
      subject={name}
      onConfirm={() => onDelete(id)}
    />
  )
}

export default Detail
