import { tenants as actionTypes } from '../actions/types'
import { reject } from 'lodash'

export default (tenants = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_TENANTS:
      return [].concat(action.tenants)
    case actionTypes.DELETED_TENANT:
      return reject(tenants, { id: action.id })
    default:
      return tenants
  }
}
