import React from 'react'
import { Table } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import { withRouter } from '../../common/withRouter'

export default withRouter(({ users, location }) => {
  if (!users) return null

  const isServiceUser = location.pathname.includes('serviceUsers')

  return (
    <Table fixed>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Id</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>
            {isServiceUser ? 'Description' : 'Email'}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {users.map((user) => {
          return (
            <Table.Row key={user.id}>
              <Table.Cell>
                <NavLink className='item' to={`/users/${user.id}/roles`}>
                  {user.id}
                </NavLink>
              </Table.Cell>
              <Table.Cell>
                {user.firstname} {user.lastname}
              </Table.Cell>
              <Table.Cell>
                {isServiceUser ? user.description : user.email}
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
})
