import React, { Component } from 'react'
import 'moment-timezone'
import { tz } from 'moment'
import { Dropdown } from 'semantic-ui-react'

class TimezonePicker extends Component {
  constructor (props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.timezones = tz.names().map(name => ({
      key: name,
      value: name,
      text: name
    }))
  }

  onChange (e, { value }) {
    this.props.onTimezone(value)
  }

  // component should never re-render
  shouldComponentUpdate () {
    return false
  }

  render () {
    return (
      <Dropdown
        search selection
        defaultValue={this.props.defaultValue}
        placeholder='Timezone'
        options={this.timezones}
        onChange={this.onChange}
      />
    )
  }
}

export default TimezonePicker
