import { combineReducers } from 'redux'

import applications from './applications'
import auth from './auth'
import concepts from './concepts'
import permissions from './permissions'
import roles from './roles'
import tenants from './tenants'
import users from './users'

export default combineReducers({
  applications,
  auth,
  concepts,
  permissions,
  roles,
  tenants,
  users
})
