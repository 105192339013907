import { compact, take, upperFirst } from 'lodash'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { withRouter } from '../common/withRouter'
import { Breadcrumb } from '@waylay/react-components'

const BreadcrumbComponent = ({ titles = [], capitalize = true }) => {
  const location = useLocation()
  const parts = compact(location.pathname.split('/'))

  const sections = parts.map((part, index) => {
    const lastPart = (index === parts.length - 1)
    const path = take(parts, index + 1).join('/')

    const title = titles[index] ?? (capitalize ? upperFirst(part) : part)

    const content = !lastPart
      ? <Link to={`/${path}`}>{title}</Link>
      : title

    return { name: content, key: index }
  })

  return (
    <Breadcrumb sections={sections} />
  )
}

export default withRouter(BreadcrumbComponent)
