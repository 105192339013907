import React, { Component } from 'react'
import { Grid, Header } from 'semantic-ui-react'
import PermissionsList from './permissionsList'

class PermissionsListManager extends Component {
  render () {
    const { rolePermissions = [], allPermissions = [] } = this.props
    return (
      <div>
        <Grid centered columns={2} divided>
          <Grid.Column>
            <Header>Available Permissions</Header>
            <PermissionsList
              permissions={allPermissions}
              itemIcon='add'
              onItemClick={id => this.props.addRolePermission(id)}
              color='teal'
            />
          </Grid.Column>
          <Grid.Column>
            <Header>Assigned Permissions</Header>
            <PermissionsList
              permissions={rolePermissions}
              itemIcon='delete'
              onItemClick={id => this.props.removeRolePermission(id)}
              color='teal'
            />
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}

export default PermissionsListManager
