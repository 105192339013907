import React from 'react'
import { Dropdown } from 'semantic-ui-react'

export default ({ applications, multiple, onApplicationSelected }) => {
  const options = convertApplicationToOptions(applications)

  return (
    <Dropdown
      search selection
      placeholder='Applications'
      options={options}
      multiple={multiple}
      onChange={(e, data) => onApplicationSelected(data.value)}
    />
  )
}

function convertApplicationToOptions (applications) {
  const ddApplications = applications.map(app => ({
    key: app.id,
    text: app.name,
    value: app.id
  }))

  // add clear selection item
  ddApplications.unshift({ key: null, text: 'No selection', value: '' })

  return ddApplications
}
