import React from 'react'
import { Link } from 'react-router-dom'
import { Table, Input } from 'semantic-ui-react'
import { isEmpty } from 'lodash'

export default ({ role, handleDescriptionChange }) => {
  if (isEmpty(role)) return null

  const { tenant, description } = role
  return (
    <Table>
      <Table.Body>
        <Table.Row>
          <Table.Cell>ID</Table.Cell>
          <Table.Cell>{role.id}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Name</Table.Cell>
          <Table.Cell>{role.name}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Description</Table.Cell>
          <Table.Cell>{renderDescription(description, handleDescriptionChange)}</Table.Cell>
        </Table.Row>
        {renderTenant(tenant)}
      </Table.Body>
    </Table>
  )
}

function renderDescription (description, onDescriptionChange) {
  if (!onDescriptionChange) return description
  return (
    <Input
      transparent fluid
      placeholder='Description...'
      defaultValue={description}
      onBlur={e => onDescriptionChange(e.target.value)}
    />
  )
}

function renderTenant (tenant) {
  if (!tenant) return null
  return (
    <Table.Row>
      <Table.Cell>Tenant</Table.Cell>
      <Table.Cell>
        <Link to={`/tenants/${tenant.id}`}>{tenant.name}</Link>
      </Table.Cell>
    </Table.Row>
  )
}
