import moment from 'moment'
import { jwtDecode } from 'jwt-decode'

const { localStorage } = window

export function isAuthenticated () {
  const token = localStorage.getItem('token')
  const hasJWTToken = Boolean(token)
  if (!hasJWTToken) return false

  const { exp } = jwtDecode(token)
  const stillValid = moment().isBefore(moment(exp * 1000))

  return stillValid
}
