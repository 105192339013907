import { createStore, applyMiddleware, compose } from 'redux'
import promises from 'redux-promise'
import app from '../reducers'

export default createStore(
  app,
  {},
  compose(
    applyMiddleware(promises),
    window.devToolsExtension ? window.devToolsExtension() : noop => noop
  )
)
