import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { differenceWith } from 'lodash'

export default ({ allTenants, tenants, addTenant }) => {
  const unassignedTenants = differenceWith(allTenants, tenants, (obj, other) => obj.id === other.id)
  const dropDownTenants = parseTenantsForDropdown(unassignedTenants, {
    onClick: (event, data) => addTenant(data.value)
  })

  return (
    <div id='top-bar'>
      <Dropdown
        selection
        search
        value=''
        options={dropDownTenants}
        placeholder='Add a tenant'
      />
    </div>
  )
}

function parseTenantsForDropdown (tenants, options) {
  const ddTenants = tenants.map(tenant => ({
    key: tenant.id,
    text: tenant.name,
    value: tenant.id,
    ...options
  }))

  // add clear selection item
  ddTenants.unshift({ key: null, text: 'No selection', value: '' })
  return ddTenants
}
