import React, { Component } from 'react'
import { Routes, Route, Navigate } from 'react-router'
import { get } from 'lodash'

import { withRouter } from '../../common/withRouter'
import Detail from '../../components/users/detail'
import { errToast } from '../../lib/toast'
import { users } from '../../actions'

const { fetchUser } = users

class UserDetailContainer extends Component {
  constructor (props) {
    super(props)
    this.state = { user: undefined }
  }

  componentDidMount () {
    const userId = get(this.props, ['match', 'params', 'id'])
    fetchUser(userId)
      .then(({ user }) => this.setState({ user }))
      .catch(errToast)
  }

  render () {
    const userId = get(this.props, ['match', 'params', 'id'])
    const { user } = this.state

    return (
      <Routes>
        <Route path='/info' element={<Detail id={userId} user={user} tab='info' updateUser={this.updateUser} />} />
        <Route path='/tenants' element={<Detail id={userId} user={user} tab='tenants' updateUser={this.updateUser} />} />
        <Route path='/keys' element={<Detail id={userId} user={user} tab='keys' updateUser={this.updateUser} />} />
        <Route path='/permissions' element={<Detail id={userId} user={user} tab='permissions' updateUser={this.updateUser} />} />
        <Route path='/roles' element={<Detail id={userId} user={user} tab='roles' updateUser={this.updateUser} />} />
        <Route path='/' element={<Navigate to={this.props.location.pathname.includes('serviceUsers') ? `/serviceUsers/${userId}/info` : `/users/${userId}/info`} />} />
      </Routes>
    )
  }

  updateUser = (user) => {
    this.setState({ user })
  }
}

export default withRouter(UserDetailContainer)
