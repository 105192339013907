import client from '../lib/client'
import { keys as types } from './types'

function createKey (userId, tenantId) {
  return client.createAPIKey(userId, tenantId)
    .then(key => ({
      type: types.API_KEY_CREATED,
      key
    }))
}

function updateKey (userId, keyId, data) {
  return client.updateAPIKey(userId, keyId, data)
    .then(key => ({
      type: types.API_KEY_UPDATED,
      key
    }))
}

function deleteKey (userId, keyId) {
  return client.deleteAPIKey(userId, keyId)
    .then(key => ({
      type: types.API_KEY_DELETED,
      key
    }))
}

export default {
  createKey,
  updateKey,
  deleteKey
}
