/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Icon, Input } from 'semantic-ui-react'
import { Button, Table } from '@waylay/react-components'
import moment from 'moment'

import ClipboardText from '../copyToClipboard'
import ConfirmationModal from '../confirmationModal'
import TenantsDropdown from '../../containers/tenantDropdown'
import TableHeaderCellBold from '../../common/tableHeaderCellBold'
import { errToast } from '../../lib/toast'
import { users } from '../../actions'

const { fetchUser, fetchUserKeys } = users

export default ({
  user,
  keys,
  handleTenantSelection,
  handleCreateKey,
  handleUpdateKey,
  handleDeleteKey
}) => {
  const location = useLocation()
  const [selectableTenants, setSelectableTenants] = useState([])
  const [userKeys, setUserKeys] = useState(keys)

  useEffect(() => {
    if (user) {
      fetchUser(user.id)
        .then(({ user }) => setSelectableTenants(user.tenants))
        .catch(errToast)

      fetchUserKeys(user.id)
        .then(({ keys }) => setUserKeys(keys))
    }
  }, [user, keys, location.pathname])

  return (
    <div>
      <div id='top-bar'>
        <TenantsDropdown tenants={selectableTenants} onTenantSelected={handleTenantSelection} />
        <Button type='primary' onClick={handleCreateKey} style={{ marginLeft: 20 }}>
          <Icon name='add' /> Add API Key
        </Button>
      </div>
      <Table style={{ width: '100%' }}>
        <Table.Header>
          <Table.Row>
            <TableHeaderCellBold>
              Tenant
            </TableHeaderCellBold>
            <TableHeaderCellBold>
              Description
            </TableHeaderCellBold>
            <TableHeaderCellBold>
              Last used
            </TableHeaderCellBold>
            <TableHeaderCellBold>ID</TableHeaderCellBold>
            <TableHeaderCellBold>Client ID</TableHeaderCellBold>
            <TableHeaderCellBold style={{ width: 120 }}>Client Secret</TableHeaderCellBold>
            <TableHeaderCellBold style={{ width: 120 }}>Actions</TableHeaderCellBold>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {userKeys.map(k => renderKey(k, handleUpdateKey, handleDeleteKey))}
        </Table.Body>
      </Table>
    </div>
  )
}

function renderKey (key, onUpdateKey, onDeleteKey) {
  const { id, description, tenant, client_id: clientId, client_secret: clientSecret, last_token_created } = key
  const updateDescription = (keyId, description) => onUpdateKey(keyId, { description })

  return (
    <Table.Row key={id}>
      <Table.Cell>{tenant.name}</Table.Cell>
      <Table.Cell>
        <Input
          fluid
          placeholder='Description…'
          defaultValue={description}
          onBlur={e => updateDescription(id, e.target.value)}
        />
      </Table.Cell>
      <Table.Cell>
        {!last_token_created ? 'never' : moment(last_token_created).fromNow()}
      </Table.Cell>
      <Table.Cell>{id}</Table.Cell>
      <Table.Cell>{clientId}</Table.Cell>
      <Table.Cell>
        <ClipboardText value={clientSecret} />
      </Table.Cell>
      <Table.Cell>
        <div>
          {renderDeleteModal(key, onDeleteKey)}
        </div>
      </Table.Cell>
    </Table.Row>
  )
}

function renderDeleteModal ({ id }, onDelete) {
  return (
    <ConfirmationModal
      header='Delete API key'
      subject='this key'
      onConfirm={() => onDelete(id)}
    />
  )
}
