import React from 'react'
import { Dropdown } from 'semantic-ui-react'

export default ({ users, onChange }) => {
  const options = usersToOptions(users)
  return (
    <Dropdown
      search selection
      placeholder='Users'
      options={options}
      onChange={(e, data) => onChange(data.value)}
    />
  )
}

function usersToOptions (users) {
  const ddUsers = users.map(user => ({
    key: user.id,
    text: user.email,
    value: user.id
  }))

  // add clear selection item
  ddUsers.unshift({ key: null, text: 'No selection', value: '' })
  return ddUsers
}
