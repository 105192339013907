import React, { Component } from 'react'
import { get } from 'lodash'

import EditView from '../../components/users/edit'
import { withRouter } from '../../common/withRouter'
import { errToast } from '../../lib/toast'
import { users } from '../../actions'

const { fetchUser, updateUser } = users

class Detail extends Component {
  constructor (props) {
    super(props)
    this.state = { user: undefined }
  }

  componentDidMount () {
    const userId = get(this.props, ['match', 'params', 'id'])
    fetchUser(userId)
      .then(({ user }) => this.setState({ user }))
      .catch(errToast)
  }

  update = (id, state) => {
    updateUser(id, state)
      .then(() => this.props.history(-1))
      .catch(errToast)
  }

  render () {
    const { user } = this.state

    if (!user) return null
    return <EditView user={user} updateUser={this.update} />
  }
}

export default withRouter(Detail)
