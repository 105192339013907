import client from '../lib/client'
import { predefinedRoles as types } from './types'

function fetchPredefinedRole (roleId) {
  return client.predefinedRole(roleId)
    .then(role => ({
      type: types.FETCH_ROLE,
      role
    }))
}

function fetchPredefinedRoles () {
  return client.predefinedRoles()
    .then(predefinedRoles => ({
      type: types.FETCH_PREDEFINED_ROLES,
      predefinedRoles
    }))
}

function addPermissionsToPredefinedRole (roleId, permissionId) {
  return client.addPermissionsToPredefinedRole(roleId, permissionId)
    .then(permission => ({
      type: types.PERMISSION_ROLE_ADDED,
      permission: { roleId, permissionId }
    }))
}

function removePermissionFromPredefinedRole (roleId, permissionId) {
  return client.removePermissionFromPredefinedRole(roleId, permissionId)
    .then(() => ({
      type: types.PERMISSION_ROLE_REMOVED,
      permission: { roleId, permissionId }
    }))
}

export default {
  fetchPredefinedRole,
  fetchPredefinedRoles,

  addPermissionsToPredefinedRole,
  removePermissionFromPredefinedRole
}
