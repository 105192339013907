import React from 'react'
import ResetPasswordForm from './passwordForm'

import '../login/login.css'

const ForgotPassword = (props) => (
  <div className='login__wrapper'>
    <ResetPasswordForm {...props} />
  </div>
)

export default ForgotPassword
