import React from 'react'
import EmailForm from './form'

import '../login/login.css'

const ForgotPassword = (props) => (
  <div className='login__wrapper'>
    <EmailForm {...props} />
  </div>
)

export default ForgotPassword
