import React, { Component } from 'react'
import Clipboard from 'clipboard'
import { Popup, Icon } from 'semantic-ui-react'
import { Button } from '@waylay/react-components'
import PropTypes from 'prop-types'

class ClipboardText extends Component {
  constructor (props) {
    super(props)
    this.state = { copied: false }
  }

  componentDidMount () {
    const button = this.button
    const { value } = this.props

    this.clipboard = new Clipboard(button, {
      text: () => value
    })
      .on('success', event => this.onSuccess(event))
  }

  onSuccess (event) {
    if (this.state.copied) return

    this.setState({ copied: true })
    setTimeout(() => {
      this.setState({ copied: false })
    }, 750)
  }

  componentWillUnmount () {
    if (this.clipboard) this.clipboard.destroy()
  }

  render () {
    return (
      <span ref={elem => { this.button = elem }}>
        <Popup
          inverted
          size='tiny'
          trigger={this.props.target}
          content='Copied!'
          open={this.state.copied}
          position='top center'
        />
      </span>
    )
  }
}

ClipboardText.defaultProps = {
  target: (
    <Button outline kind='secondary' size='small'>
      <Icon name='clipboard' /> Copy
    </Button>
  )
}

ClipboardText.propTypes = {
  value: PropTypes.string,
  target: PropTypes.node
}

export default ClipboardText
